import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import Operations from '../Operations/Operations';
import { Alert } from '@mui/material';
import RouterIcon from '@mui/icons-material/Router';
import { handleDateExpires } from '../../../../helpers/helpers';

const Problem = ({ clientData }) => {
  const [problemExpiresDate, setProblemExpiresDate] = useState('');

  useEffect(() => {
    if (clientData.client?.service?.switchDTO?.problem) {
      const expires = clientData.client?.service?.switchDTO?.problemExpires;
      const date = handleDateExpires(expires);
      setProblemExpiresDate(date);
    }
  }, []);

  return clientData.client?.service?.switchDTO?.problem ? (
    <Alert
      severity="warning"
      sx={{ mt: 1, border: '1px solid #e0e0e0' }}
      iconMapping={{
        warning: <RouterIcon style={{ color: '#6b4400' }} />,
      }}
    >
      <strong>Об аварии:</strong>
      {clientData.client?.service?.switchDTO?.problemComment}
      <br />
      <strong>Срок устранения:</strong> {problemExpiresDate}
    </Alert>
  ) : (
    ''
  );
};

Operations.propTypes = {
  clientData: pt.object,
};

export default Problem;
