import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Typography,
  CustTextField,
  Label,
  GreenTextField,
  RedTextField,
  TextArea,
  useStyles,
} from '../AbonentResults';

import pt from 'prop-types';

const Diagnostic = ({ clientData }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography>Диагностическая</Typography>
      <CardContent style={{ padding: 0 }}>
        <Box style={{ padding: 10 }}>
          <Label>Статус терминала</Label>
          {clientData.diagnostic?.online ? (
            <GreenTextField value="Онлайн" />
          ) : (
            <RedTextField value="Офлайн" />
          )}

          <Label>Дата изменения статуса</Label>
          {clientData.diagnostic?.online ? (
            <CustTextField value={clientData.diagnostic?.lastUptime || ''} />
          ) : (
            <CustTextField value={clientData.diagnostic?.lastDowntime || ''} />
          )}

          <Label>Уровень сигнала</Label>
          <CustTextField
            value={
              clientData.diagnostic?.txPower && clientData.diagnostic?.rxPower
                ? clientData.diagnostic?.txPower +
                  '/' +
                  clientData.diagnostic?.rxPower
                : ''
            }
          />

          <Label>Статусы портов</Label>
          <TableContainer
            style={{
              marginBottom: 13,
              border: '1px solid #e0e0e0',
              borderBottom: 0,
              borderRadius: 4,
            }}
          >
            <Table>
              <TableHead style={{ background: '#f8f9fa' }}>
                <TableRow>
                  {clientData.diagnostic?.portList?.map((port) => (
                    <TableCell className={classes.tableCellHead}>
                      {port.type + port.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {clientData.diagnostic?.portList?.map((port) => (
                    <TableCell className={classes.tableCell}>
                      {String(port.link)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {clientData.diagnostic?.portList?.map((port) => (
                    <TableCell className={classes.tableCell}>
                      {port.speed}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Label>MAC</Label>
          <TextArea
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            inputProps={{ readOnly: true }}
            value={
              clientData.diagnostic?.macAddresses?.map(
                (address, i) =>
                  String(i === 0 ? '' : ' ,') +
                  address?.mac +
                  ' (' +
                  address?.vlan +
                  ')'
              ) || ''
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Diagnostic.propTypes = {
  clientData: pt.object,
};

export default Diagnostic;
