import React, { forwardRef } from "react";
import { styled as MuiStyled, styled } from "@mui/material/styles";
import {
  alpha,
  TextField as MuiTextField,
  InputBase,
  Tooltip,
  tooltipClasses,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Switch,
} from "@mui/material";

import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";

export const RedditTextField = styled(
  forwardRef((props, ref) => (
    <MuiTextField
      inputRef={ref}
      style={{ width: props.width }}
      InputProps={{
        disableUnderline: true,
      }}
      fullWidth
      variant="filled"
      {...props}
    />
  ))
)(({ theme }) => ({
  "& .MuiFilledInput-input": {
    padding: 0,
    marginLeft: 7,
  },
  "& .MuiFilledInput-root": {
    border: "1px solid #e0e0e0",
    overflow: "hidden",
    borderRadius: 4,
    height: "40px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const BootstrapInput = styled(InputBase)(({ setHeight, theme }) => ({
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    height: setHeight,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #e0e0e0",
    padding: "7.7px 10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:hover": {
      borderColor: theme.palette.primary.main,
    },

    "&:focus": {
      backgroundColor: "transparent",
      borderColor: theme.palette.primary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    },
  },
}));

export const Card = styled(MuiCard)`
  box-shadow: 0 2px 6px var(--gray-shadow);
`;

export const CardContent = styled(MuiCardContent)``;

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const FacebookCircularProgress = (props) => {
  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          top: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(3px)",
  },

  "& .MuiDialogContent-root": {
    minHeight: 150,
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const TextArea = styled(RedditTextField)(() => ({
  "& .MuiFilledInput-root": {
    paddingTop: 5,
    paddingLeft: 3,
    height: "fit-content",
  },
}));

export const TimePicker = styled(RedditTextField)(() => ({
  "& .MuiFilledInput-root": {
    paddingRight: 7,
  },
}));

export const Label = styled("label")`
  font-size: 14px;
  display: block;
  margin-bottom: 3px;
`;

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      {...other}
      style={{
        fontSize: "17px",
        fontWeight: "400",
        height: 48,
        padding: "12px 0 0 16px",
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 7,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CustButton = MuiStyled(MuiButton)(
  ({
    theme,
    backgroundHover,
    background = "var(--blue)",
    border = "none",
  }) => ({
    height: 40,
    borderRadius: 4,
    padding: "6px 14px",
    fontSize: "12px",
    color: "black",
    // color: "white",
    width: "100%",
    fontWeight: 400,
    background,
    border,
    // background: "var(--blue)",
    backgroundOrigin: "border-box",
    boxShadow:
      "0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2)",

    "&:focus": {
      boxShadow:
        !background &&
        "inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5)",
      outline: 0,
    },
    "&:hover": {
      background: backgroundHover || "var(--blue-hover)",
    },

    svg: {
      color: theme.palette.primary.main,
    },
  })
);

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : blue[500],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
