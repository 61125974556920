import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  switches: [],
  statusChanging: false,
  clientsMoving: false,
  closeModal: 'default',
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_SWITCH_LIST:
      return { ...state, switches: [...payload] };
    case types.CHANGE_SWITCH_STATUS_IN_PROGRESS:
      return { ...state, statusChanging: true };
    case types.CHANGE_SWITCH_STATUS_IN_ERROR:
      toast.error(payload);
      return {
        ...state,
        statusChanging: false,
      };
    case types.CHANGE_SWITCH_STATUS:
      toast.success('Статус изменен');
      return {
        ...state,
        statusChanging: false,
        switches: state.switches.map((s) =>
          s.id === payload.id ? payload : s
        ),
      };
    case types.CLIENTS_MOVE:
      toast.success('Клиенты успешно перемещены');
      return {
        ...state,
        clientsMoving: false,
      };
    case types.CLIENTS_MOVE_IN_PROGRESS:
      return {
        ...state,
        clientsMoving: true,
      };
    case types.CLIENTS_MOVE_IN_ERROR:
      toast.error(
        `Произошла ошибка, не удалось переместить. Код ошибки ${payload}`
      );
      return {
        ...state,
        clientsMoving: false,
      };
    case 'CHANGE_DONE':
      return { ...state, closeModal: 'close' };
    case 'CHANGE_RESET':
      return { ...state, closeModal: 'default' };
    default:
      return state;
  }
}
