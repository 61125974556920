import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const collectInvoice =
  (year, month, setCollectLoading, state) => async (dispatch) => {
    try {
      dispatch({ type: 'COLLECT_RESET' });

      const { data } = await axios(url.COLLECT_INVOICE + `/${year}/${month}`);

      // console.log(data, "collect");

      setCollectLoading({
        ...state,
        collect: { ...state.collect, state: false },
      });

      dispatch({
        type: type.COLLECT_INVOICE,
        payload: data,
      });
    } catch (e) {
      // console.log(e.response, "error");
    }
  };

export const makeInvoice =
  (year, month, setUnloadLoading, state) => async (dispatch) => {
    try {
      dispatch({ type: 'MAKE_RESET' });

      const { data } = await axios(url.MAKE_INVOICE + `/${year}/${month}`);

      // console.log(data, "make");

      setUnloadLoading({
        ...state,
        unload: { ...state.unload, state: false },
      });

      dispatch({
        type: type.MAKE_INVOICE,
        payload: data,
      });
    } catch (e) {
      // console.log(e.response, "error");
    }
  };

export const clearInvoice =
  (year, month, setClearLoading, state) => async (dispatch) => {
    try {
      dispatch({ type: 'CLEAR_RESET' });

      const { data } = await axios(url.CLEAR_INVOICE + `/${year}/${month}`);

      // console.log(data, "clear");

      setClearLoading({
        ...state,
        clear: { ...state.clear, state: false },
      });

      dispatch({
        type: type.CLEAR_INVOICE,
        payload: data,
      });
    } catch (e) {
      setClearLoading({
        ...state,
        clear: { ...state.clear, state: false },
      });
      dispatch({
        type: type.CLEAR_INVOICE_IN_ERROR,
        payload: `${e.response?.data?.message}. ${e.response?.status}`,
      });
      // console.log(e.response, "error");
    }
  };

export const formInvoice =
  (year, month, setFormingLoading, state, objParameters) =>
  async (dispatch) => {
    try {
      dispatch({ type: 'FORM_RESET' });

      const {
        durationRM,
        chargeRM,
        durationRF,
        chargeRF,
        durationMN,
        chargeMN,
      } = objParameters;

      const { data } = await axios(
        url.FORM_INVOICE +
          `/${year}/${month}?durationRM=${durationRM}&chargeRM=${chargeRM}&durationRF=${durationRF}&chargeRF=${chargeRF}&durationMN=${durationMN}&chargeMN=${chargeMN}`
      );

      // console.log(data, "forming");

      setFormingLoading({
        ...state,
        forming: { ...state.forming, state: false },
      });

      dispatch({
        type: type.FORM_INVOICE,
        payload: data,
      });
    } catch (e) {
      // console.log(e.response, "error");
    }
  };
