import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  reports: [],
  clientList: [],
  client: [],
  clientId: '',
  csv: {
    csvLoading: false,
    fileName: '',
    success: false,
    error_message: '',
  },
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_CLIENTS_REPORT:
      return { ...state, reports: [...payload] };
    case types.CLIENT_LIST:
      return { ...state, clientList: [...payload] };
    case types.GET_CLIENT_DETAIL:
      return {
        ...state,
        client: [...payload.data],
        clientId: String(payload.clientId),
      };
    case types.GET_CSV_SUCCESS:
      return { ...state, csv: { csvLoading: false, ...payload } };
    case types.GET_CSV_ERROR:
      toast.error(payload.error_message);
      return { ...state, csv: { csvLoading: false, ...payload } };
    case types.GET_CSV:
      return {
        ...state,
        csv: { ...state.csv, fileName: '', csvLoading: true },
      };
    case types.CLEAR_CSV_FILE:
      return {
        ...state,
        csv: initialState.csv,
      };
    default:
      return state;
  }
}
