import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFails } from '../redux/actions/failActions';
import Layout from '../Layout/Layout';
import { Container } from '@mui/material';
import FailCard from '../components/FailCard/FailCard';
import FailContainer from '../components/FailContainer/FailContainer';

const Fails = ({ dispatch, fails, loading }) => {
  useEffect(() => {
    dispatch(getFails());
  }, []);

  return (
    <Layout>
      <Container>
        <FailContainer loading={loading} fails={fails}>
          {Object.values(fails)?.map((fail, i) => (
            <FailCard key={fail.id} fail={fail} index={i} />
          ))}
        </FailContainer>
      </Container>
    </Layout>
  );
};

const mapStateStateToProps = (state) => {
  return {
    fails: state.failsReducer.fails,
    loading: state.failsReducer.loading,
  };
};

export default connect(mapStateStateToProps)(Fails);
