import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const getSwitchList = () => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.SWITCH_LIST);

    dispatch({
      type: type.GET_SWITCH_LIST,
      payload: data,
    });
  } catch (e) {
    // console.log(e.response);
  }
};

export const changeSwitchStatus = (status) => async (dispatch) => {
  try {
    dispatch({ type: type.CHANGE_SWITCH_STATUS_IN_PROGRESS });

    const { data } = await axios.post(
      url.PREFIX + url.CHANGE_SWITCH_STATUS,
      status
    );
    // console.log(data);

    dispatch({
      type: type.CHANGE_SWITCH_STATUS,
      payload: { ...data.object, problemExpires: data.expiredAt },
    });

    dispatch({
      type: 'CHANGE_DONE',
    });

    dispatch({
      type: 'CLEAR_ABONENT',
    });
  } catch (e) {
    dispatch({
      type: type.CHANGE_SWITCH_STATUS_IN_ERROR,
      payload: `${e.response.data?.message} ${e.response?.status}`,
    });
    // console.log(e.response);
  }
};

export const clientsMove =
  ({ oldSwitch, newSwitch, oldVlan, newVlan }) =>
  async (dispatch) => {
    try {
      dispatch({ type: type.CLIENTS_MOVE_IN_PROGRESS });

      await axios.post(
        url.PREFIX +
          url.CLIENTS_MOVE +
          `?oldSwitch=${oldSwitch}&oldVlan=${oldVlan}&newSwitch=${newSwitch}&newVlan=${newVlan}`
      );
      dispatch({ type: type.CLIENTS_MOVE });
    } catch (e) {
      dispatch({
        type: type.CLIENTS_MOVE_IN_ERROR,
        payload: e.response?.status,
      });
      // console.log(e.response, 'error');
    }
  };
