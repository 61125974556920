import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getSwitchList } from "../../redux/actions/switchActions";
import ReactTable from "../ReactTable/ReactTable";
import { switchListColumns } from "./table/switchListColumns";

function SwitchList({ dispatch, switches }) {
  useEffect(() => {
    dispatch(getSwitchList());
  }, []);

  return (
    <ReactTable
      data={switches}
      columns={switchListColumns}
      isEditColumn={true}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    switches: state.switchReducers.switches,
  };
};

export default connect(mapStateToProps)(SwitchList);
