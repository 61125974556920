import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getSwitchList } from "../../redux/actions/switchActions";
import ReactTable from "../ReactTable/ReactTable";
import { clientsOnColumns } from "./columns/clientsOnColumns";

import { getSwitchClients } from "../../redux/actions/clientsOnActions";

function ClientsOn({ dispatch, switchClients, switches, currentSwitchId }) {
  const initialClientId = () => {
    if (currentSwitchId) {
      if (sessionStorage.getItem("switchId") == currentSwitchId) {
        return currentSwitchId;
      } else {
        sessionStorage.setItem("switchId", currentSwitchId);
        return currentSwitchId;
      }
    } else if (sessionStorage.getItem("switchId")) {
      return sessionStorage.getItem("switchId");
    } else {
      return "";
    }
  };

  const [switchId, setSwitchId] = useState(initialClientId);
  const [clientsOnloading, setClientsOnLoading] = useState(false);

  const handleUpdateClientsOn = () => {
    setClientsOnLoading(true);
    dispatch(getSwitchClients(switchId, setClientsOnLoading));
  };

  useEffect(() => {
    dispatch(getSwitchList());
  }, []);

  // console.log(switches);

  useEffect(() => {
    if (
      switches.length &&
      !switchId &&
      sessionStorage.getItem("switchId") !== undefined
    ) {
      setSwitchId(switches[0].id);
      sessionStorage.setItem("clientId", switchId);
    }
  }, [switches]);

  useEffect(() => {
    sessionStorage.setItem("switchId", switchId);
  }, [switchId]);

  return (
    <>
      <ReactTable
        columns={clientsOnColumns}
        data={switchClients}
        clientsOn={true}
        stateId={switchId}
        setStateId={setSwitchId}
        array={switches}
        handleUpdate={handleUpdateClientsOn}
        loading={clientsOnloading}
      />
    </>
  );
}

const mapStateTopProps = (state) => {
  return {
    switchClients: state.clientsOnReducers.switchClients,
    switches: state.switchReducers.switches,
    currentSwitchId: state.clientsOnReducers.switchId,
  };
};

export default connect(mapStateTopProps)(ClientsOn);
