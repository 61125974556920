import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  collectInvoice: {},
  makeInvoice: {},
  clearInvoice: {},
  formInvoice: {},
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.COLLECT_INVOICE:
      return {
        ...state,
        collectInvoice: { ...payload },
      };
    case types.MAKE_INVOICE:
      return {
        ...state,
        makeInvoice: { ...payload },
      };
    case types.CLEAR_INVOICE:
      return {
        ...state,
        clearInvoice: { ...payload },
      };
    case types.CLEAR_INVOICE_IN_ERROR:
      toast.error(payload);
    case types.FORM_INVOICE:
      return {
        ...state,
        formInvoice: { ...payload },
      };
    case 'COLLECT_RESET':
      return { ...state, collectInvoice: {} };
    case 'MAKE_RESET':
      return { ...state, makeInvoice: {} };
    case 'FORM_RESET':
      return { ...state, formInvoice: {} };
    case 'CLEAR_RESET':
      return { ...state, clearInvoice: {} };
    default:
      return state;
  }
}
