import React from 'react';
import {
  Grid,
  alpha,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Box,
} from '@mui/material';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { RedditTextField } from '../../customComponents/ReuseComponents';

import pt from 'prop-types';
import Main from './Main/Main';
import Technical from './Technical/Technical';
import Diagnostic from './Diagnostic/Diagnostic';
import Operations from './Operations/Operations';
import Problem from './Problem/Problem';

export const useStyles = makeStyles({
  card: {
    transition: '0.3s',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.1) !important',
  },
  tableCell: {
    paddingTop: '6.5px !important',
    paddingBottom: '6.5px !important',
  },
  tableCellHead: {
    paddingTop: '3.5px !important',
    paddingBottom: '3.5px !important',
  },
});

export const Typography = MuiStyled(MuiTypography)`
  ${spacing}
   box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1) !important;
  // background: #e7e7e7;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 15px;
`;

export const Label = styled('label')`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const TextArea = styled(RedditTextField)(() => ({
  '& .MuiFilledInput-root': {
    fontSize: 14,
    minHeight: 60,
    backgroundColor: '#f8f9fa !important',
    paddingTop: 25,
    paddingLeft: 0,
  },
}));

export const CustTextField = MuiStyled((props) => (
  <MuiTextField
    fullWidth
    InputProps={{
      disableUnderline: true,
    }}
    inputProps={{
      readOnly: true,
    }}
    variant="filled"
    {...props}
  />
))(({ theme }) => ({
  marginBottom: 13,

  '&:last-child': {
    marginBottom: 0,
  },
  '& .MuiFilledInput-input': {
    fontSize: 14,
    padding: 0,
    marginLeft: 7,
  },
  '& .MuiFilledInput-root': {
    border: '1px solid #e0e0e0',
    overflow: 'hidden',
    borderRadius: 4,
    height: '36px',
    backgroundColor: '#f8f9fa',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const RedTextField = styled(CustTextField)(() => ({
  '& .MuiFilledInput-input': {
    color: 'red',
  },
}));

export const GreenTextField = styled(CustTextField)(() => ({
  '& .MuiFilledInput-input': {
    color: 'green',
  },
}));

function AbonentResults({ clientData, loading }) {
  return (
    clientData && (
      <Box mb={5}>
        <Problem clientData={clientData} />

        <Grid sx={{ marginTop: 0 }} container spacing={1}>
          <Grid xs={12} md={6} lg={4} item>
            <Main clientData={clientData} />
          </Grid>

          <Grid xs={12} md={6} lg={3} item>
            <Technical clientData={clientData} />
          </Grid>

          <Grid xs={12} md={6} lg={3} item>
            <Diagnostic clientData={clientData} />
          </Grid>

          <Grid xs={12} md={6} lg={2} item>
            <Operations clientData={clientData} />
          </Grid>
        </Grid>
      </Box>
    )
  );
}

AbonentResults.propTypes = {
  clientData: pt.object,
  loading: pt.bool,
};

export default React.memo(AbonentResults);
