import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const getLteList = () => async (dispatch) => {
  try {
    dispatch({ type: type.LTE_LIST_IN_PROGRESS });

    const { data } = await axios(url.PREFIX + url.LTE_LIST);

    dispatch({
      type: type.LTE_LIST,
      payload: data,
    });
  } catch (e) {
    dispatch({ type: type.LTE_LIST_IN_ERROR });
    console.log(e.response, 'error');
  }
};

export const getLteById = (id) => async (dispatch) => {
  try {
    dispatch({ type: type.GET_LTE_BY_ID_LOADING_IN_PROGRESS });

    const { data } = await axios(url.PREFIX + url.GET_LTE_BY_ID + `/${id}`);

    dispatch({
      type: type.GET_LTE_BY_ID,
      payload: data,
    });
  } catch (e) {
    dispatch({ type: type.GET_LTE_BY_ID_LOADING_IN_ERROR });
    console.log(e.response, 'error');
  }
};

export const removeLteById = (id) => async (dispatch) => {
  try {
    dispatch({ type: type.LTE_REMOVE_IN_PROGRESS });

    const { data } = await axios.post(url.PREFIX + url.LTE_REMOVE + `/${id}`);

    dispatch({
      type: type.LTE_REMOVE,
      payload: { ...data, id },
    });
  } catch (e) {
    dispatch({
      type: type.LTE_REMOVE_IN_ERROR,
      payload: e.response.data.message,
    });
    console.log(e.response, 'error');
  }
};

export const saveLte = (lte, navigate) => async (dispatch) => {
  try {
    dispatch({ type: type.GET_LTE_BY_ID_LOADING_IN_PROGRESS });

    const { data } = await axios.post(url.PREFIX + url.SAVE_LTE, lte);

    console.log(data, 'data');

    if (lte.id) {
      dispatch({
        type: type.SAVE_LTE,
        payload: data,
      });
    } else {
      dispatch({
        type: type.ADD_LTE,
        payload: data,
      });
    }

    navigate('/ltepoints');
  } catch (e) {
    dispatch({
      type: type.GET_LTE_BY_ID_LOADING_IN_ERROR,
      payload: `${e.response.data?.message} ${e.response?.status}`,
    });
    console.log(e.response, 'error');
  }
};

export const lteDiagnostic = (id) => async (dispatch) => {
  try {
    dispatch({ type: type.LTE_DIAG_IN_PROGRESS });

    const { data } = await axios(url.PREFIX + url.LTE_DIAG + id);
    // console.log(data);

    dispatch({
      type: type.LTE_DIAG,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: type.LTE_DIAG_IN_ERROR,
      payload: e.response.data.message,
    });
    // console.log(e.response, 'error');
  }
};

export const checkPing = () => async (dispatch) => {
  try {
    dispatch({ type: type.CHECK_PING_IN_PROGRESS });

    const { data } = await axios(url.PREFIX + url.CHECK_PING);

    console.log(data);

    dispatch({
      type: type.CHECK_PING,
      payload: data.body,
    });
  } catch (e) {
    dispatch({ type: type.CHECK_PING_IN_ERROR });
    console.log(e.response, 'error');
  }
};
