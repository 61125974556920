import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  taskList: {},
  taskChecking: [],
  taskResult: {},
  resultInError: '',
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_TASK_LIST:
      return {
        ...state,
        taskList: { ...payload },
      };
    case types.TASK_CHECKING:
      return {
        ...state,
        taskChecking: [...payload],
      };
    case types.ADD_TASK:
      return {
        ...state,
        taskChecking: [...state.taskChecking, payload],
      };
    case types.ADD_TASK_ERROR:
      toast.error(payload);
    case types.GET_RESULT:
      return {
        ...state,
        resultInError: '',
        taskResult: { ...payload },
      };
    case types.GET_RESULT_IN_ERROR:
      return {
        ...state,
        resultInError: payload,
      };
    case 'CLEAR_TASK_RESULT':
      return {
        ...state,
        taskResult: {},
      };
    default:
      return state;
  }
}
