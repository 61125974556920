import * as React from "react";

import {
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  ListItemText,
  Checkbox as MuiCheckbox,
} from "@mui/material";

import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box } from "@mui/system";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { BootstrapInput } from "../../customComponents/ReuseComponents";

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 4,
      boxShadow: "0px 3px 5px #e0e0e0",
      border: "1px solid #e0e0e0",
      minWidth: 170,
    },
  },
};

const MenuItem = styled(MuiMenuItem)`
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
`;

const Checkbox = styled(MuiCheckbox)`
  svg {
    font-size: 18px;
  }
`;

const Select = styled(MuiSelect)`
  svg {
    margin-right: 3px;
  }
`;

function ColumnsCheck({ columns, toggleAll }) {
  return (
    //  Toggle Columns #4
    <Box ml={1}>
      <Select
        input={<BootstrapInput />}
        MenuProps={MenuProps}
        IconComponent={FormatListBulletedIcon}
      >
        <label>
          <MenuItem style={{ borderBottom: "1px solid #e0e0e0" }}>
            <Checkbox
              style={{ fontSize: "10px" }}
              size="small"
              {...toggleAll()}
            />
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Все поля"
            />
          </MenuItem>
        </label>
        <Box ml={1}>
          {columns?.map((column) => (
            <label key={column.id}>
              <MenuItem key={column.id}>
                <Checkbox size="small" {...column.getToggleHiddenProps()} />
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={column.Header}
                />
              </MenuItem>
            </label>
          ))}
        </Box>
      </Select>
    </Box>
  );
}

export default ColumnsCheck;
