import React, { useEffect, useState } from 'react';
import './menuBar.css';
import { Link } from 'react-router-dom';
import { checkAuth } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

function MenuBar() {
  const isAuth = useSelector((state) => state.authReducers.isAuth);
  const dispatch = useDispatch();

  const [arrow, setArrow] = useState({
    reports: false,
    services: false,
    settings: false,
    utm: false,
  });

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const handleArrow = (value) => setArrow(value);

  return (
    <>
      {Object.keys(isAuth).length ? (
        <>
          <nav className="nav-block">
            <div className="logo">Billing</div>
            <label htmlFor="btn" className="icon">
              <span className="fa fa-bars"></span>
            </label>
            <input type="checkbox" id="btn" />
            <ul>
              <li>
                <Link to="/">Главная</Link>
              </li>

              <li>
                <label
                  htmlFor="btn-1"
                  className="show"
                  onClick={() =>
                    handleArrow({ ...arrow, reports: !arrow.reports })
                  }
                >
                  Отчеты
                  <i
                    className={
                      arrow.reports
                        ? 'fa-solid fa-caret-down arrow-active'
                        : 'fa-solid fa-caret-down'
                    }
                  />
                </label>
                <a href="#">
                  Отчеты
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa-solid fa-caret-down nohover"
                  />
                </a>
                <input type="checkbox" id="btn-1" />

                <ul>
                  <li>
                    <Link to="/clientsReport">По клиентам</Link>
                  </li>
                  <li>
                    <Link to="/clientsReport/detail">Детализация</Link>
                  </li>
                  <li>
                    <Link to="/billing/invoice">Вымпелком</Link>
                  </li>
                </ul>
              </li>

              <li>
                <label
                  htmlFor="btn-2"
                  className="show"
                  onClick={() =>
                    handleArrow({ ...arrow, services: !arrow.services })
                  }
                >
                  Сервисы
                  <i
                    className={
                      arrow.services
                        ? 'fa-solid fa-caret-down arrow-active'
                        : 'fa-solid fa-caret-down'
                    }
                  />
                </label>
                <a href="#">
                  Сервисы <i className="fa-solid fa-caret-down nohover"></i>
                </a>
                <input type="checkbox" id="btn-2" />
                <ul>
                  <li>
                    <Link to="/sptools/clientData/">Проверка абонента</Link>
                  </li>
                  <li>
                    <Link to="/ltepoints">Проверка LTE</Link>
                  </li>
                  <li>
                    <Link to="/export">Фоновые задания</Link>
                  </li>
                  <li>
                    <Link to="/sptools/bySW">Клиенты на коммутаторе</Link>
                  </li>
                </ul>
              </li>

              <li>
                <label
                  htmlFor="btn-3"
                  className="show"
                  onClick={() =>
                    handleArrow({ ...arrow, settings: !arrow.settings })
                  }
                >
                  Настройки
                  <i
                    className={
                      arrow.settings
                        ? 'fa-solid fa-caret-down arrow-active'
                        : 'fa-solid fa-caret-down'
                    }
                  />
                </label>
                <a href="#">
                  Настройки <i className="fa-solid fa-caret-down nohover"></i>
                </a>
                <input type="checkbox" id="btn-3" />

                <ul>
                  {isAuth.admin ? (
                    <li>
                      <Link to="/users">Пользователи</Link>
                    </li>
                  ) : (
                    ''
                  )}
                  <li>
                    <Link to="/findPosition">Местоположение</Link>
                  </li>
                </ul>
              </li>

              <li>
                <label
                  htmlFor="btn-0"
                  className="show"
                  onClick={() => handleArrow({ ...arrow, utm: !arrow.utm })}
                >
                  UTM
                  <i
                    className={
                      arrow.utm
                        ? 'fa-solid fa-caret-down arrow-active'
                        : 'fa-solid fa-caret-down'
                    }
                  />
                </label>
                <a href="#">
                  UTM
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa-solid fa-caret-down nohover"
                  />
                </a>
                <input type="checkbox" id="btn-0" />

                <ul>
                  <li>
                    <Link to="/utm/switches">Статусы коммутаторов</Link>
                  </li>
                  <li>
                    <Link to="/utm/clients-move">Перенос клиентов</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div style={{ marginBottom: 25 }}></div>
        </>
      ) : (
        <div style={{ marginBottom: 50 }}></div>
      )}
    </>
  );
}

export default MenuBar;
