import { Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStatus } from '../../../redux/actions/userActions';
import {
  FacebookCircularProgress,
  IOSSwitch,
} from '../../customComponents/ReuseComponents';

const CheckBoxCell = ({ value, row }) => {
  const dispatch = useDispatch();
  const { user, onlySwitchLoading: loading } = useSelector(
    (state) => state.userReducers
  );

  const onChange = () => {
    if (
      window.confirm(
        `Вы уверены что хотите изменить статус учетной записи ${row.original.username} ?`
      )
    ) {
      dispatch(changeStatus({ ...row.original, active: !value }));
    }
  };

  return (
    <Box style={{ display: 'flex' }}>
      <IOSSwitch
        checked={value}
        onChange={onChange}
        disabled={
          loading
            ? true
            : user.id && loading
            ? user.id !== row.original.id
            : false
        }
      />
      {user.id === row.original.id && loading ? (
        <FacebookCircularProgress style={{ position: 'absolute', left: 10 }} />
      ) : (
        ''
      )}
    </Box>
  );
};

const EditCell = () => {
  const { onlySwitchLoading: loading } = useSelector(
    (state) => state.userReducers
  );

  return (
    <IconButton style={{ padding: 10 }} disabled={loading}>
      <FontAwesomeIcon icon={faPenToSquare} fontSize={18} />
    </IconButton>
  );
};

export const userListColumns = [
  {
    Header: 'Статус',
    accessor: 'active',
    Cell: CheckBoxCell,
    width: 70,
  },
  {
    Header: 'Логин',
    accessor: 'username',
  },
  {
    Header: 'Фамилия',
    accessor: 'lastName',
  },
  {
    Header: 'Имя',
    accessor: 'firstName',
  },
  {
    Header: 'Роли',
    accessor: (row) =>
      row.roles?.length ? '[' + row.roles.map((role) => role) + ']' : '',
  },
  {
    id: 'edit-column',
    Cell: EditCell,
  },
];
