import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import pt from "prop-types";

import {
  clearCsvFile,
  clientsDetailReportCsv,
  getClientDetail,
  getClientList,
} from "../../redux/actions/clientsReportActions";
import ReactTable from "../ReactTable/ReactTable";
import { clientColumns } from "./columns/clientColumns";
import moment from "moment";

const initialDate = (type) => {
  if (type === "start") {
    return moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
  } else {
    return moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
  }
};

function ClientDetails({ dispatch, clientList, client, currentClientId }) {
  const [dateStart, setDateStart] = useState(
    sessionStorage.getItem("dateStart") || initialDate("start")
  );

  const { csvLoading } = useSelector(
    (state) => state.clientsReportReducers.csv
  );

  const initialClientId = () => {
    if (currentClientId) {
      if (sessionStorage.getItem("clientId") == currentClientId) {
        return currentClientId;
      } else {
        sessionStorage.setItem("clientId", currentClientId);
        return currentClientId;
      }
    } else if (sessionStorage.getItem("clientId")) {
      return sessionStorage.getItem("clientId");
    } else {
      return "";
    }
  };

  const [dateEnd, setDateEnd] = useState(
    sessionStorage.getItem("dateEnd") || initialDate
  );

  const [clientId, setClientId] = useState(initialClientId);

  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    sessionStorage.setItem("dateStart", dateStart);
    sessionStorage.setItem("dateEnd", dateEnd);
    setLoading(true);
    dispatch(getClientDetail(dateStart, dateEnd, clientId, setLoading));
  };

  const handleCsv = () => {
    dispatch(clientsDetailReportCsv({ dateEnd, dateStart, clientId }));
  };

  useEffect(() => {
    if (
      !loading &&
      !clientId &&
      sessionStorage.getItem("clientId") !== undefined
    ) {
      setClientId(clientList[0]?.clientId_n);
      sessionStorage.setItem("clientId", clientId);
    }
  }, [loading]);

  useEffect(() => {
    sessionStorage.setItem("clientId", clientId);
  }, [clientId]);

  useEffect(() => {
    if (!clientList.length) {
      setLoading(true);
      dispatch(getClientList(setLoading));
    }
  }, []);

  useEffect(() => {
    return () => dispatch(clearCsvFile());
  }, []);

  console.log(clientList);

  return (
    <ReactTable
      data={client}
      clientDateEnd={dateEnd}
      clientDateStart={dateStart}
      setClientDateEnd={setDateEnd}
      setClientDateStart={setDateStart}
      clientLoading={loading}
      csvLoading={csvLoading}
      clientHandleUpdate={handleUpdate}
      columns={clientColumns}
      stateId={clientId}
      setStateId={setClientId}
      array={clientList}
      clientDetails={true}
      csv={true}
      handleCsv={handleCsv}
    />
  );
}

ClientDetails.propTypes = {
  dispatch: pt.func,
  clientList: pt.array,
  client: pt.array,
  currentClientId: pt.string,
};

const mapStateTopProps = (state) => {
  return {
    clientList: state.clientsReportReducers.clientList,
    client: state.clientsReportReducers.client,
    currentClientId: state.clientsReportReducers.clientId,
  };
};

export default connect(mapStateTopProps)(ClientDetails);
