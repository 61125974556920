import { numberFormat } from "../../../helpers/helpers";

export const clientsReportColumns = [
  {
    Header: "Клиент",
    accessor: "client.name",
    width: 470,
  },
  {
    Header: "Провайдер",
    accessor: "client.ourCompany",
  },
  {
    Header: "Вымпелком",
    accessor: "client.sovintel",
  },
  {
    Header: "МН",
    accessor: "abroadSum",
    Cell: ({ value }) => numberFormat(value),
  },
  {
    Header: "Местные",
    accessor: "localSum",
    Cell: ({ value }) => numberFormat(value),
  },
  {
    Header: "Мобильные",
    accessor: "mobileSum",
    Cell: ({ value }) => numberFormat(value),
  },
  {
    Header: "МГ",
    accessor: "countrySum",
    Cell: ({ value }) => numberFormat(value),
  },
  {
    Header: "Сумма",
    accessor: "chargeSum",
    Cell: ({ value }) => numberFormat(value),
  },
];
