import moment from "moment";
import formatter from "format-number";

export const handleDateExpires = (dateExpires) => {
  if (moment(dateExpires).isSame(new Date(), "day")) {
    return "Сегодня в " + moment(dateExpires).format("HH:mm");
  } else if (moment(dateExpires).isSame(moment().add(1, "day"), "day")) {
    return "Завтра в " + moment(dateExpires).format("HH:mm");
  } else {
    return moment(dateExpires).format("DD MMM около HH:mm");
  }
};

export const numberFormat = (number) => {
  return formatter({ integerSeparator: " ", truncate: 2 })(number);
};
