export const SIGN_IN = "/api/login";

export const PAGE_PREFIX = "";
export const PREFIX = "/api";
export const SWITCH_LIST = "/switch/list";
export const GET_ABONENTS = "/sptools/clientData";
export const CHANGE_SWITCH_STATUS = "/switch/addToProblem";
export const GET_CLIENTS_REPORT = "/api/calls/report";

export const COLLECT_INVOICE = "/api/collect-inv";
export const MAKE_INVOICE = "/api/makeinvoice";
export const CLEAR_INVOICE = "/api/clear-invoice";
export const FORM_INVOICE = "/api/bee-report";

export const CLIENT_LIST = "/v2/client/list";

export const GET_CLIENT_DETAIL = "/v2/client/detail";
export const CHECK_AUTH = "/checkSession";
export const CREATE_ISSUE = "/api/sptools/clientData/createIssue/";
export const SEND_PASSWORD = "/sptools/sendPassword/";
export const REBOOT_ONT = "/api/sptools/reboot";
export const CREATE_PROMISE_PAY = "/api/sptools/clientData/createPromisePay/";
export const GET_TASK_LIST = "/v2/export/list";
export const TASK_CHECKING = "/v2/export/check";
export const GET_RESULT = "/v2/export/result";

export const GET_SWITCH_CLIENTS = "/switch/clients";
export const USER_LIST = "/user/list";
export const LTE_LIST = "/lte/list";
export const LTE_REMOVE = "/lte/delete";
export const GET_LTE_BY_ID = "/lte/get";
export const SAVE_LTE = "/lte/save";
export const LTE_DIAG = "/lte/diag/";
export const GET_USER_BY_ID = "/user/get";
export const ROLE_LIST = "/user/list-role";
export const SAVE_USER = "/user/save";
export const CHECK_PING = "/lte/ping";

export const CLIENTS_MOVE = "/tool/utm/move-between-sw";
export const GET_FAILS = "/skou/get-fail";
