import React from 'react';
import { Card, CardContent } from '@mui/material';
import { HtmlTooltip } from '../../../customComponents/ReuseComponents';
import { numberFormat } from '../../../../helpers/helpers';
import pt from 'prop-types';

import {
  Typography,
  CustTextField,
  Label,
  RedTextField,
  TextArea,
  useStyles,
} from '../AbonentResults';

const Main = ({ clientData }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography>Основная информация</Typography>
      <CardContent style={{ padding: 10 }}>
        <Label>Логин</Label>
        <CustTextField value={clientData.client?.login || ''} />
        <Label>ФИО/Название</Label>
        <CustTextField value={clientData.client?.fullName || ''} />
        <Label>Номер телефона</Label>
        <CustTextField value={clientData.client?.mobilePhone || ''} />
        <Label>Баланс</Label>

        {clientData.client?.blocked ? (
          <HtmlTooltip title="Абонент заблокирован">
            <RedTextField
              value={
                clientData.client?.balance
                  ? numberFormat(clientData.client?.balance)
                  : ''
              }
            />
          </HtmlTooltip>
        ) : (
          <CustTextField
            value={
              clientData.client?.balance
                ? numberFormat(clientData.client?.balance)
                : ''
            }
          />
        )}

        <Label>Тариф</Label>
        <CustTextField value={clientData.client?.mainTariff?.tariffName} />

        <Label>Адрес</Label>
        <TextArea
          inputProps={{ readOnly: true }}
          fullWidth
          multiline
          minRows={3}
          maxRows={3}
          value={clientData.client?.actualAddress || ''}
        />
      </CardContent>
    </Card>
  );
};

Main.propTypes = {
  clientData: pt.object,
};

export default Main;
