import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Container } from "@mui/material";
import { Map, Placemark } from "@pbe/react-yandex-maps";

interface IPosition {
  latitude: number;
  longitude: number;
}

const usePosition = () => {
  const [position, setPosition] = useState<IPosition>({
    latitude: 0,
    longitude: 0,
  });
  const [error, setError] = useState(null);

  const onChange = ({
    coords: { latitude, longitude },
  }: GeolocationPosition) => {
    setPosition({ latitude, longitude });
  };

  const onError = (error: GeolocationPositionError) => {
    setError(error.message);
  };

  useEffect(() => {
    const geolocation = navigator.geolocation;

    if (!geolocation) {
      setError("Геолокация не поддерживается браузером");
      return;
    }

    const watcher = geolocation.watchPosition(onChange, onError);

    return () => geolocation.clearWatch(watcher);
  }, []);

  return {
    ...position,
    error,
  };
};

function FindPosition() {
  const { longitude, latitude, error } = usePosition();

  return (
    <Layout>
      <Container>
        {!error ? (
          <div>
            <Map
              style={{ width: "100%", height: "calc(100vh - 300px)" }}
              defaultState={{
                center: [latitude, longitude],
                zoom: 17,
              }}
            >
              <Placemark
                modules={["geoObject.addon.balloon"]}
                defaultGeometry={[latitude, longitude]}
                properties={{
                  balloonContentBody: `Ваши координаты: ${latitude}, ${longitude}`,
                }}
              />
            </Map>
          </div>
        ) : (
          error
        )}
      </Container>
    </Layout>
  );
}

export default FindPosition;
