import * as types from '../../constants/redux';

const initialState = {
  fails: {},
  loading: false,
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_FAILS:
      return {
        ...state,
        fails: payload,
        loading: false,
      };
    case types.GET_FAILS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FAILS_IN_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
