import axios from "axios";
import * as url from "../../constants/urls";
import * as type from "../../constants/redux";

export const getSwitchClients = (switchId, setLoading) => async (dispatch) => {
  try {
    const { data } = await axios(
      url.PREFIX + url.GET_SWITCH_CLIENTS + `?switchId=${switchId}`
    );

    dispatch({
      type: type.GET_SWITCH_CLIENTS,
      payload: { clients: data, switchId },
    });

    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e.response, "error");
  }
};

// export const sendMessage = (switchId, message, setLoading) => async () => {
//   try {
//     await axios(`sdlfsf?message=${message}`);
//
//     setLoading({
//       state: false,
//       success: "success",
//     });
//   } catch (e) {
//     setLoading({
//       state: false,
//       success: "error",
//     });
//     console.log(e.response, "error");
//   }
// };
