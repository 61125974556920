import React, { useEffect } from "react";
import { connect } from "react-redux";

import pt from "prop-types";

import ReactTable from "../ReactTable/ReactTable";
import { lteListColumns } from "./table/lteColumns";
import { getLteList } from "../../redux/actions/lteCheckActions";

function LteCheck({ dispatch, lteList }) {
  useEffect(() => {
    dispatch({ type: "CLEAR_LTE" });
    if (!lteList.length || lteList.length === 1) {
      dispatch(getLteList());
    }
  }, []);

  return (
    <ReactTable columns={lteListColumns} data={lteList} addButton={true} />
  );
}

LteCheck.propTypes = {
  dispatch: pt.func,
  lteList: pt.array,
  loading: pt.bool,
};

const mapStateTopProps = (state) => {
  return {
    lteList: state.lteCheckReducers.lteList,
    loading: state.lteCheckReducers.loading,
  };
};

export default connect(mapStateTopProps)(LteCheck);
