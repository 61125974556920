import React from 'react';
import styles from './FailCard.module.css';

const FailCard = ({ fail, index }) => {
  return (
    <div className={styles.card}>
      <p className={styles.order}>{index + 1}</p>
      <p className={styles.failId}>
        <span>ID</span> {fail.id}
      </p>
      <h3 className={styles.title}>{fail.title}</h3>
      <p className={styles.ipAddress}>IP {fail.ip_address}</p>
    </div>
  );
};

export default FailCard;
