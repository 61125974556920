import * as types from "../../constants/redux";

const initialState = {
  isAuth: {},
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.CHECK_AUTH:
      return {
        ...state,
        isAuth: { ...payload },
      };
    default:
      return state;
  }
}
