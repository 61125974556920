import * as types from "../../constants/redux";

const initialState = {
  switchClients: [],
  switchId: "",
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_SWITCH_CLIENTS:
      return {
        ...state,
        switchClients: [...payload.clients],
        switchId: payload.switchId,
      };

    default:
      return state;
  }
}
