import React from 'react';
import { Card, CardContent, InputAdornment } from '@mui/material';

import pt from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { useStyles } from '../AbonentResults';

import {
  Typography,
  Label,
  RedTextField,
  CustTextField,
} from '../AbonentResults';

const Technical = ({ clientData }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography>Техническая информация</Typography>
      <CardContent style={{ padding: 10 }}>
        <Label>Коммутатор</Label>
        {clientData.client?.service?.switchDTO?.problem ? (
          <>
            <RedTextField
              style={{ marginBottom: 3 }}
              value={clientData.client?.service?.switchDTO?.name || ''}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ color: 'orange' }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <CustTextField
            style={{
              marginBottom: clientData.client?.service?.switchDTO?.address
                ? 3
                : 13,
            }}
            value={clientData.client?.service?.switchDTO?.name || ''}
          />
        )}

        {clientData.client?.service?.switchDTO && (
          <a
            href={'http://' + clientData.client?.service?.switchDTO?.ipS}
            target="_blank"
            style={{
              fontSize: 14,
              marginBottom: 13,
              display: 'inline-block',
            }}
          >
            К коммутатору
          </a>
        )}

        <Label>Коммутатор адрес</Label>
        <CustTextField value={clientData.client?.service?.address || ''} />
        <Label>IP адрес</Label>
        <CustTextField value={clientData.client?.service?.ipS || ''} />
        <Label>ONT</Label>
        <CustTextField value={clientData.client?.service?.portId || ''} />
        <Label>PON</Label>
        <CustTextField value={clientData.client?.service?.vlan || ''} />
      </CardContent>
    </Card>
  );
};

Technical.propTypes = {
  clientData: pt.object,
};

export default Technical;
