import React from 'react';
import MenuBar from './components/MenuBar/MenuBar';
import './app.css';
import { Provider } from 'react-redux';
import { rootReducer } from './redux/reducers';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import thunk from 'redux-thunk';
import SwitchList from './components/Switches/SwitchList';
import NotFound from './components/NotFound';
import Abonent from './components/Abonent/Abonent';
import ClientsReport from './components/ClientsReport/ClientsReport';
import Invoice from './components/Incoice/Invoice';
import ClientDetails from './components/ClientDetails/ClientDetails';
import { composeWithDevTools } from 'redux-devtools-extension';
import BackgroundTasks from './components/BackgroundTasks/BackgroundTasks';
import ClientsOn from './components/ClientsOn/ClientsOn';
import Users from './components/Users/Users';
import LteCheck from './components/CheckLte/LteCheck';
import LteCheckForm from './components/CheckLte/LteCheckForm/LteCheckForm';
import 'react-toastify/dist/ReactToastify.css';
import Toastify from './components/Toastify/Toastify';
import SignIn from './components/Auth/SignIn';
import ClientsMove from './components/ClientsMove/ClientsMove';
import FindPosition from './components/FindPosition/FindPosition';
import { YMaps } from '@pbe/react-yandex-maps';
import Fails from './pages/Fails';

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

function App() {
  return (
    <YMaps>
      <Provider store={store}>
        <BrowserRouter>
          <Toastify />
          <Routes>
            <Route path="/" element={<Fails />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/utm/switches" element={<SwitchList />} />
            <Route path="/utm/clients-move" element={<ClientsMove />} />
            <Route path="/sptools/clientData" element={<Abonent />} />
            <Route path="/clientsReport" element={<ClientsReport />} />
            <Route path="/ltepoints" element={<LteCheck />} />
            <Route path="/lte-edit/:id" element={<LteCheckForm />} />
            <Route path="/lte-add" element={<LteCheckForm />} />
            <Route path="/clientsReport/detail" element={<ClientDetails />} />
            <Route path="/billing/invoice" element={<Invoice />} />
            <Route path="/export" element={<BackgroundTasks />} />
            <Route path="/sptools/bySW" element={<ClientsOn />} />
            <Route path="/users" element={<Users />} />
            <Route path="/findPosition" element={<FindPosition />} />
            <Route path="*" element={NotFound} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </YMaps>
  );
}

export default App;
