import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import pt from "prop-types";
import {
  getResult,
  getTaskList,
  taskChecking,
  taskStart,
} from "../../redux/actions/backgroundTaskActions";
import { styled as MuiStyled } from "@mui/material/styles";
import {
  BootstrapInput,
  Card,
  CardContent,
  CustButton,
  FacebookCircularProgress,
  TimePicker,
} from "../customComponents/ReuseComponents";
import {
  Box,
  Button as MuiButton,
  Container,
  Grid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Typography,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";

import styled from "styled-components/macro";
import { blue } from "@mui/material/colors";

import DoneIcon from "@mui/icons-material/Done";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Layout from "../../Layout/Layout";

const useStyles = makeStyles({
  card: {
    transition: "0.3s",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1) !important",
  },
});

const Label = styled("label")`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

const ClientSelect = styled(BootstrapInput)({
  "& .MuiInputBase-input": {
    padding: "0",
    height: 38,
    borderRadius: 4,
    textAlign: "center",
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 4,
      boxShadow: "0px 3px 5px #e0e0e0",
      border: "1px solid #e0e0e0",
    },
  },
};

const MenuItem = styled(MuiMenuItem)`
  font-size: 14px !important;
`;

const Select = styled(MuiSelect)`
  display: flex !important;
`;

const CustomSelect = styled.div`
  font-size: 15px;
  min-height: 110px;
  max-height: 110px;
  border-radius: 4px;
  background: #fcfcfb;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  padding: 5px 10px;

  &:hover {
    background: white;
    border-color: ${blue[500]};
  }

  &:focus {
    background: white;
  }
`;

const TaskResult = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid #e0e0e0;
  background: #fcfcfb;
  min-height: 35px;
  border-radius: 4px;
  width: 100%;
  padding: 4px 10px;

  &:before {
    font-size: 14px;
    color: grey;
    content: "${(props) => (props.isResultExist ? "" : "Результат")}";
  }
`;

const CustomOption = styled.span`
  display: flex;
  align-items: center;
  padding: 3px 5px;
  cursor: pointer;
  background: ${(props) => (props.selectedOption ? "#cecece" : "transparent")};

  &:focus {
    color: white;
    background: ${blue[500]};
  }
`;

function BackgroundTasks({
  dispatch,
  taskList,
  tasks,
  taskResult,
  resultInError,
}) {
  const classes = useStyles();
  const [currentTask, setCurrentTask] = useState({
    key: "",
    executor: "",
    name: "",
    params: null,
  });
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentOption, setCurrentOption] = useState(null);
  const [fileRequired, setFileRequired] = useState(false);
  const [taskCheckState, setTaskCheckState] = useState(false);
  const [optionFromParams, setOptionFromParams] = useState({});
  const [dateFrom, setDateFrom] = useState(moment().format("YYYY-MM-DD"));
  const [isExistResult, setIsExistResult] = useState(null);
  const [taskId, setTaskId] = useState("");
  const [dateTo, setDateTo] = useState(
    moment().add(7, "days").format("YYYY-MM-DD")
  );

  useEffect(() => {
    dispatch(getTaskList(setLoading));
  }, []);

  useEffect(() => {
    if (!taskCheckState) {
      const timer = setTimeout(() => {
        if (
          tasks[currentOption]?.future?.done &&
          isExistResult !== currentOption
        ) {
          dispatch(getResult(taskId));
          setIsExistResult(currentOption);
        }

        setTaskCheckState(true);
        dispatch(taskChecking(setTaskCheckState));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [taskCheckState]);

  useEffect(() => {
    setTaskCheckState(true);
    dispatch(taskChecking(setTaskCheckState));
  }, []);

  useEffect(() => {
    if (!loading) {
      setCurrentTask({
        ...Object.values(taskList)[0],
        key: Object.keys(taskList)[0],
      });
      if (Object.values(taskList)[0].params) {
        if (
          Object.values(taskList)[0].params.some(
            (param) => param.type === "select"
          )
        ) {
          Object.values(taskList)[0].params.forEach((param) =>
            param.type === "select"
              ? setOptionFromParams({
                  ...optionFromParams,
                  [param.name]: Object.keys(param.options)[0],
                })
              : false
          );
        }
      }
    }
  }, [loading]);

  const handleSubmit = () => {
    if (currentTask.params) {
      if (currentTask.params.some((param) => param.type === "file")) {
        if (!file.name) {
          setFileRequired(true);
          return;
        }
      }

      setFileRequired(false);
      dispatch(
        taskStart({
          ...currentTask,
          dateTo,
          dateFrom,
          options: { ...optionFromParams },
          file,
        })
      );
      setFile({});
    } else {
      dispatch(taskStart(currentTask, "", setCurrentOption));
    }
  };

  const handleChangeTask = (key) => {
    setFileRequired(false);
    setFile({});
    setCurrentTask({ ...taskList[key], key });
    if (taskList[key].params) {
      if (taskList[key].params.some((param) => param.type === "select")) {
        taskList[key].params.forEach((param) =>
          param.type === "select"
            ? setOptionFromParams({
                ...optionFromParams,
                [param.name]: Object.keys(param.options)[0],
              })
            : false
        );
      }
    }
  };

  const handleFile = (f) => {
    if (f) {
      setFileRequired(false);
      setFile(f);
    }
  };

  const handleCustomSelect = (e) => {
    if (tasks[e.target.id].future.done) {
      dispatch(getResult(e.target.dataset.value));
      setIsExistResult(e.target.id);
      setCurrentOption(e.target.id);
      setTaskId(e.target.dataset.value);
    } else {
      dispatch({ type: "CLEAR_TASK_RESULT" });
      setCurrentOption(e.target.id);
      setTaskId(e.target.dataset.value);
    }
  };

  return (
    <Layout>
      <Container>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Label>Тип задачи</Label>
                <Select
                  fullWidth
                  MenuProps={MenuProps}
                  input={<ClientSelect />}
                  onChange={(e) => handleChangeTask(e.target.value)}
                  value={currentTask.key}
                >
                  {Object.values(taskList).map((task, i) => (
                    <MenuItem key={i} value={Object.keys(taskList)[i]}>
                      {task.name}
                    </MenuItem>
                  ))}
                </Select>
                <Box mt={2}>
                  <CustButton fullWidth onClick={handleSubmit}>
                    Выполнить
                  </CustButton>

                  {currentTask.params?.length ? (
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                      {currentTask.params.map((param, i) => {
                        return param.type === "file" ? (
                          <React.Fragment key={i}>
                            <Grid item>
                              <CustButton
                                component='label'
                                onChange={(e) => handleFile(e.target.files[0])}
                                style={{ minWidth: 100 }}
                                background='var(--gray-light)'
                                backgroundHover='var(--gray-hover)'
                                border='1px solid var(--gray)'
                              >
                                ФАЙЛ
                                <input
                                  type='file'
                                  hidden
                                  // accept=".xlsx, .xls, .csv .zip .rar"
                                />
                              </CustButton>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                color={fileRequired ? "red" : "green"}
                              >
                                {fileRequired ? "Выберите файл" : file?.name}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ) : param.type === "select" ? (
                          <Grid
                            key={i}
                            item
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label>{param.description}&nbsp;</label>
                            <Select
                              MenuProps={MenuProps}
                              input={<ClientSelect />}
                              value={optionFromParams[param.name]}
                              onChange={(e) =>
                                setOptionFromParams({
                                  ...optionFromParams,
                                  [param.name]: e.target.value,
                                })
                              }
                            >
                              {Object.values(param.options).map((option, i) => (
                                <MenuItem
                                  key={option}
                                  value={Object.keys(param.options)[i]}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        ) : param.type === "date" ? (
                          <Grid
                            key={i}
                            item
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label>{param.description}&nbsp;</label>
                            <TimePicker
                              onChange={(e) =>
                                param.name === "dateFrom"
                                  ? setDateFrom(e.target.value)
                                  : param.name === "dateTo"
                                  ? setDateTo(e.target.value)
                                  : ""
                              }
                              variant='filled'
                              InputProps={{
                                disableUnderline: true,
                              }}
                              type='date'
                              defaultValue={
                                param.name === "dateFrom"
                                  ? dateFrom
                                  : param.name === "dateTo"
                                  ? dateTo
                                  : ""
                              }
                              height={40}
                            />
                          </Grid>
                        ) : (
                          ""
                        );
                      })}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Label>Список задач</Label>
                <CustomSelect
                  onClick={handleCustomSelect}
                  style={{ marginBottom: 15 }}
                >
                  {tasks.length
                    ? tasks.map((task, i) => (
                        <CustomOption
                          tabIndex={0}
                          key={task.taskId}
                          id={i}
                          data-value={task.taskId}
                          selectedOption={task.taskId === taskId}
                        >
                          {task.entity.name}
                          {task.future.done && task.success ? (
                            <DoneIcon
                              style={{
                                color: "green",
                                marginLeft: 3,
                                fontSize: 18,
                              }}
                            />
                          ) : task.future.done && !task.success ? (
                            <ErrorOutlineIcon
                              style={{
                                color: "red",
                                marginLeft: 3,
                                fontSize: 18,
                              }}
                            />
                          ) : !task.future.done ? (
                            <FacebookCircularProgress
                              style={{ marginLeft: 5, width: 15, height: 15 }}
                            />
                          ) : (
                            ""
                          )}
                        </CustomOption>
                      ))
                    : ""}
                </CustomSelect>

                <TaskResult
                  href={taskResult.path}
                  download
                  isResultExist={
                    !(!taskResult.result && !resultInError && !taskResult.path)
                  }
                >
                  {Array.isArray(taskResult.result)
                    ? taskResult.result.map((res) => (
                        <span>{res.fileName}</span>
                      ))
                    : ""}
                  {taskResult.path || resultInError}
                </TaskResult>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Layout>
  );
}

BackgroundTasks.propTypes = {
  dispatch: pt.func,
  taskList: pt.object,
  tasks: pt.array,
  taskResult: pt.shape({
    path: pt.string,
    result: pt.oneOfType([pt.array, pt.string]),
  }),
  resultInError: pt.string,
};

const mapStateTopProps = (state) => {
  return {
    taskList: state.backgroundTaskReducers.taskList,
    tasks: state.backgroundTaskReducers.taskChecking,
    taskResult: state.backgroundTaskReducers.taskResult,
    resultInError: state.backgroundTaskReducers.resultInError,
  };
};

export default connect(mapStateTopProps)(BackgroundTasks);
