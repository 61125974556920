import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Grid,
  Typography,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Checkbox,
  CardContent,
} from "@mui/material";
import {
  BootstrapInput,
  Card,
  CustButton,
  FacebookCircularProgress,
  Label,
  RedditTextField,
} from "../customComponents/ReuseComponents";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import {
  clearInvoice,
  collectInvoice,
  formInvoice,
  makeInvoice,
} from "../../redux/actions/invoiceActions";
import Layout from "../../Layout/Layout";

const Select = styled(MuiSelect)(spacing);
const MonthSelect = styled(MuiSelect)`
  ${spacing}
  min-width: 130px;
`;

const useStyles = makeStyles({
  card: {
    minHeight: 150,
    transition: "0.3s",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1) !important",
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 4,
      boxShadow: "0px 3px 5px #e0e0e0",
      border: "1px solid #e0e0e0",
      minWidth: 80,
      maxWidth: 100,
    },
  },
};

const MenuItem = styled(MuiMenuItem)`
  font-size: 14px !important;
`;

const MenuItemYear = styled(MuiMenuItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
`;

const CustLabel = styled("label")`
  display: inline-block;
  min-width: 40px;
  font-size: 14px;
`;

const FieldType = styled("p")`
  position: absolute;
  left: 50%;
  right: 50%;
  width: 100px;
  top: -18px;
  font-size: 14px;
  transform: translate(-25%, -25%);
  z-index: 100;
`;

const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

const currentMonth = new Date().getMonth() + 1;

const date = new Date();
const years = [
  date.getFullYear() - 1,
  date.getFullYear(),
  date.getFullYear() + 1,
];

function Invoice({ invoices, dispatch }) {
  const classes = useStyles();
  const [checkRemove, setCheckRemove] = useState(false);
  const [formingState, setFormingState] = useState({
    durationRM: "",
    chargeRM: "",
    durationRF: "",
    chargeRF: "",
    durationMN: "",
    chargeMN: "",
  });
  const [invoicesState, setInvoicesState] = useState({
    collectInvoice: {
      month: currentMonth,
      year: years[1],
      state: false,
    },
    makeInvoice: {
      month: currentMonth,
      year: years[1],
      state: false,
    },
    clearInvoice: {
      month: currentMonth,
      year: years[1],
      state: false,
    },
    formInvoice: {
      month: currentMonth,
      year: years[1],
      state: false,
    },
  });
  const [checkForm, setCheckForm] = useState(true);

  const makeUn = (func, type) => {
    setInvoicesState({
      ...invoicesState,
      [type]: { ...invoicesState[type], state: true },
    });
    return dispatch(
      func(
        invoicesState[type].year,
        invoicesState[type].month,
        setInvoicesState,
        invoicesState,
        type === "formInvoice" && formingState
      )
    );
  };

  const handleCollect = () => {
    makeUn(collectInvoice, "collectInvoice");
  };
  const handleUnload = () => {
    makeUn(makeInvoice, "makeInvoice");
  };
  const handleClear = () => {
    makeUn(clearInvoice, "clearInvoice");
  };
  const handleForming = () => {
    if (
      formingState.durationRM === "" ||
      formingState.chargeRM === "" ||
      formingState.durationRF === "" ||
      formingState.chargeRF === "" ||
      formingState.durationMN === "" ||
      formingState.chargeMN === ""
    ) {
      setCheckForm(false);
    } else {
      setCheckForm(true);
      makeUn(formInvoice, "formInvoice");
    }
  };

  const changeState = (value, key, property) => {
    setInvoicesState({
      ...invoicesState,
      [key]: { ...invoicesState[key], [property]: value },
    });
  };

  const handleFormingProperty = (value, key) => {
    setFormingState({ ...formingState, [key]: value });
  };

  return (
    <Layout>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Сборка данных для счетов</Typography>
                <Label>за период</Label>

                <Box
                  mt={1}
                  mb={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MonthSelect
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.collectInvoice.month}
                    onChange={(e) =>
                      changeState(e.target.value, "collectInvoice", "month")
                    }
                    mr={1}
                  >
                    {months.map((month, i) => (
                      <MenuItem key={month} value={i + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </MonthSelect>
                  <Select
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.collectInvoice.year}
                    onChange={(e) =>
                      changeState(e.target.value, "collectInvoice", "year")
                    }
                    mr={1}
                  >
                    {years.map((year) => (
                      <MenuItemYear year={year} key={year} value={year}>
                        {year}
                      </MenuItemYear>
                    ))}
                  </Select>
                  <CustButton
                    style={{
                      height: 40,
                    }}
                    disabled={invoicesState.collectInvoice.state}
                    onClick={handleCollect}
                    endIcon={
                      invoicesState.collectInvoice.state ? (
                        <FacebookCircularProgress />
                      ) : (
                        ""
                      )
                    }
                  >
                    Собрать
                  </CustButton>
                </Box>
                {invoices.collectInvoice.success ? (
                  <Typography>{invoices.collectInvoice.message}</Typography>
                ) : (
                  <Typography color='red'>
                    {invoices.collectInvoice.error_message}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography>Выгрузка документов</Typography>
                <Label>за период</Label>

                <Box
                  mt={1}
                  mb={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MonthSelect
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.makeInvoice.month}
                    onChange={(e) =>
                      changeState(e.target.value, "makeInvoice", "month")
                    }
                    mr={1}
                  >
                    {months.map((month, i) => (
                      <MenuItem key={month} value={i + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </MonthSelect>
                  <Select
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.makeInvoice.year}
                    onChange={(e) =>
                      changeState(e.target.value, "makeInvoice", "year")
                    }
                    mr={1}
                  >
                    {years.map((year) => (
                      <MenuItemYear year={year} key={year} value={year}>
                        {year}
                      </MenuItemYear>
                    ))}
                  </Select>

                  <CustButton
                    style={{
                      height: 40,
                    }}
                    disabled={invoicesState.makeInvoice.state}
                    onClick={handleUnload}
                    endIcon={
                      invoicesState.makeInvoice.state ? (
                        <FacebookCircularProgress />
                      ) : (
                        ""
                      )
                    }
                  >
                    Загрузить
                  </CustButton>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {invoices.makeInvoice.success ? (
                    invoices.makeInvoice.files.map((file) => (
                      <a href={invoices.makeInvoice.baseUrl + file} download>
                        {file.slice(1)}
                      </a>
                    ))
                  ) : (
                    <Typography color='red'>
                      {invoices.makeInvoice.error_message}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Удаление собранных данных</Typography>
                <Label>за период</Label>

                <Box
                  mt={1}
                  mb={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MonthSelect
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.clearInvoice.month}
                    onChange={(e) =>
                      changeState(e.target.value, "clearInvoice", "month")
                    }
                    mr={1}
                  >
                    {months.map((month, i) => (
                      <MenuItem key={month} value={i + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </MonthSelect>
                  <Select
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.clearInvoice.year}
                    onChange={(e) =>
                      changeState(e.target.value, "clearInvoice", "year")
                    }
                    mr={1}
                  >
                    {years.map((year) => (
                      <MenuItemYear year={year} key={year} value={year}>
                        {year}
                      </MenuItemYear>
                    ))}
                  </Select>

                  <CustButton
                    style={{
                      height: 40,
                    }}
                    disabled={!checkRemove || invoicesState.clearInvoice.state}
                    onClick={handleClear}
                    endIcon={
                      invoicesState.clearInvoice.state ? (
                        <FacebookCircularProgress />
                      ) : (
                        ""
                      )
                    }
                  >
                    Удалить
                  </CustButton>
                  <Checkbox
                    checked={checkRemove}
                    onChange={(e) => setCheckRemove(e.target.checked)}
                  />
                </Box>
                {invoices.clearInvoice.success ? (
                  <Typography>{invoices.clearInvoice.message}</Typography>
                ) : (
                  <Typography color='red'>
                    {invoices.clearInvoice.error_message}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Формирование отчета Вымпелком</Typography>
                <Label>за период</Label>

                <Box
                  mt={1}
                  mb={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MonthSelect
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.formInvoice.month}
                    onChange={(e) =>
                      changeState(e.target.value, "formInvoice", "month")
                    }
                    mr={1}
                  >
                    {months.map((month, i) => (
                      <MenuItem key={month} value={i + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </MonthSelect>
                  <Select
                    MenuProps={MenuProps}
                    input={<BootstrapInput />}
                    value={invoicesState.formInvoice.year}
                    onChange={(e) =>
                      changeState(e.target.value, "formInvoice", "year")
                    }
                    mr={1}
                  >
                    {years.map((year) => (
                      <MenuItemYear year={year} key={year} value={year}>
                        {year}
                      </MenuItemYear>
                    ))}
                  </Select>

                  <CustButton
                    style={{
                      height: 40,
                    }}
                    disabled={invoicesState.formInvoice.state}
                    onClick={handleForming}
                    endIcon={
                      invoicesState.formInvoice.state ? (
                        <FacebookCircularProgress />
                      ) : (
                        ""
                      )
                    }
                  >
                    Сформировать
                  </CustButton>
                </Box>
                <Grid container spacing={1} sx={{ marginBottom: 2 }}>
                  <Grid
                    item
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginTop: 25,
                    }}
                  >
                    <CustLabel>ВЗ</CustLabel>

                    <Box sx={{ marginRight: 1, position: "relative" }}>
                      <FieldType>кол-во</FieldType>
                      <RedditTextField
                        sx={{ height: 35 }}
                        value={formingState.durationRM}
                        onChange={(e) =>
                          handleFormingProperty(e.target.value, "durationRM")
                        }
                      />
                    </Box>
                    <span style={{ position: "relative" }}>
                      <FieldType>сумма</FieldType>
                      <RedditTextField
                        sx={{ height: 35 }}
                        value={formingState.chargeRM}
                        onChange={(e) =>
                          handleFormingProperty(e.target.value, "chargeRM")
                        }
                      />
                    </span>
                  </Grid>
                  <Grid item style={{ alignItems: "center", display: "flex" }}>
                    <CustLabel>МГ</CustLabel>
                    <RedditTextField
                      sx={{ marginRight: 1, height: 35 }}
                      value={formingState.durationRF}
                      onChange={(e) =>
                        handleFormingProperty(e.target.value, "durationRF")
                      }
                    />
                    <RedditTextField
                      sx={{ height: 35 }}
                      value={formingState.chargeRF}
                      onChange={(e) =>
                        handleFormingProperty(e.target.value, "chargeRF")
                      }
                    />
                  </Grid>
                  <Grid item style={{ alignItems: "center", display: "flex" }}>
                    <CustLabel>МН</CustLabel>
                    <RedditTextField
                      sx={{ marginRight: 1, height: 35 }}
                      value={formingState.durationMN}
                      onChange={(e) =>
                        handleFormingProperty(e.target.value, "durationMN")
                      }
                    />
                    <RedditTextField
                      sx={{ height: 35 }}
                      value={formingState.chargeMN}
                      onChange={(e) =>
                        handleFormingProperty(e.target.value, "chargeMN")
                      }
                    />
                  </Grid>
                </Grid>

                {!checkForm ? (
                  <Typography color='red'>Заполните поля</Typography>
                ) : (
                  ""
                )}

                {invoices.formInvoice.success ? (
                  <a href={invoices.formInvoice.url} download>
                    {invoices.formInvoice.url.slice(1)}
                  </a>
                ) : (
                  <Typography color='red'>
                    {invoices.formInvoice.error_message}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {/*<Typography>{invoices.formInvoice}</Typography>*/}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

const mapStateTopProps = (state) => {
  return { invoices: state.invoiceReducers };
};

export default connect(mapStateTopProps)(Invoice);
