import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const switchListColumns = [
  {
    Header: "Имя",
    accessor: "name",
  },
  {
    Header: "Адрес",
    accessor: "address",
  },
  {
    Header: "IP",
    accessor: "ipS",
  },
  {
    id: "edit-column",
    Cell: () => (
      <IconButton style={{ padding: 10 }}>
        <FontAwesomeIcon icon={faPenToSquare} fontSize={18} />
      </IconButton>
    ),
  },
];
