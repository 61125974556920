import React, { useState } from "react";
import { connect } from "react-redux";

import pt from "prop-types";
import {
  Box,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import {
  Card,
  CustButton,
  Label,
  RedditTextField,
} from "../customComponents/ReuseComponents";
import { signIn } from "../../redux/actions/authActions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function SignIn({ dispatch }) {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = () => dispatch(signIn(user, navigate));

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Box
      style={{
        display: "grid",
        placeContent: "center",
        height: "100vh",
        background: "#f8f9fa",
      }}
    >
      <Container maxWidth="xs">
        <Card>
          <CardContent style={{ position: "relative" }}>
            <Typography align="center" sx={{ marginBottom: 1 }}>
              Авторизация
            </Typography>
            <Divider style={{ width: "100%", position: "absolute", left: 0 }} />
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12}>
                <Label>Логин:</Label>
                <RedditTextField
                  fullWidth
                  name="username"
                  value={user.username}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Пароль:</Label>
                <RedditTextField
                  fullWidth
                  onKeyPress={handleKeyPress}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={user.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustButton fullWidth onClick={handleSubmit}>
                  Войти
                </CustButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

SignIn.propTypes = {
  dispatch: pt.func,
};

export default connect()(SignIn);
