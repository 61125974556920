import React, { useEffect, useState } from "react";
import LteDiagram from "../Diagram/LteDiagram.tsx";
import LteTable from "../LteTable/LteTable.tsx";
import { connect } from "react-redux";
import "./LteCheckForm.css";

import pt from "prop-types";

import {
  Box,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Card,
  CustButton,
  FacebookCircularProgress,
  Label,
  RedditTextField,
} from "../../customComponents/ReuseComponents";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  getLteById,
  lteDiagnostic,
  saveLte,
} from "../../../redux/actions/lteCheckActions";
import Layout from "../../../Layout/Layout";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const ArrowIcon = styled(ArrowBackIcon)`
  cursor: pointer;
  position: relative;
  font-size: 20px;
  margin-right: 5px;
  color: gray;

  :hover {
    color: #3d3d3d;
  }
`;

function LteCheckForm({ dispatch, loading, lte, diagLoading }) {
  const navigate = useNavigate();
  const matches = useMediaQuery(`(max-width:1200px)`);
  const [diagnosticError, setDiagnosticError] = useState(false);

  const [state, setState] = useState({
    id: "",
    address: "",
    vlans: "",
    ip: "",
    district: "",
    contact: "",
    cms: "",
    aciz: "",
    comment: "",
    login: "",
    password: "",
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getLteById(id));
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      ...state,
    },
  });

  useEffect(() => {
    if (!loading && id) {
      reset(lte);
    }
  }, [loading]);

  const handleDiagnostic = () => {
    if (getValues("ip") && getValues("login") && getValues("password")) {
      dispatch(lteDiagnostic(lte.id));
      setDiagnosticError(false);
    } else {
      setDiagnosticError(true);
    }
  };

  const handleUptime = (time) => {
    return String(time)
      .replace("w", "нед ")
      .replace("d", "д ")
      .replace("h", "ч ")
      .replace("m", "м ")
      .replace("s", "с");
  };

  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const onSubmit = (data) => dispatch(saveLte(data, navigate));

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSubmit(onSubmit);
    }
  };

  return (
    <Layout>
      <Container sx={{ marginTop: !matches ? 5 : 0, marginBottom: 5 }}>
        <Card>
          <CardContent onKeyPress={handleKeyPress}>
            <Box
              style={{
                display: "flex",
                marginBottom: 5,
              }}
            >
              {!matches && (
                <Link to='/ltepoints'>
                  <ArrowIcon />
                </Link>
              )}

              <Typography>{id ? "Редактирование" : "Добавление"} </Typography>
            </Box>
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container spacing={3}>
              <Grid item lg={3} md={4} xs={12}>
                <Label>Район</Label>

                <Controller
                  name='district'
                  control={control}
                  rules={{ required: { value: true, message: "Заполните" } }}
                  render={({ field }) => <RedditTextField {...field} />}
                />
                <ErrorMessage
                  errors={errors}
                  name='district'
                  render={({ message }) => (
                    <p className='lteFormError'>{message}</p>
                  )}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <Label>CMS</Label>

                <Controller
                  name='cms'
                  control={control}
                  render={({ field }) => <RedditTextField {...field} />}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <Label>VLAN</Label>

                <Controller
                  name='vlans'
                  control={control}
                  render={({ field }) => <RedditTextField {...field} />}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <Label>IP</Label>

                <Controller
                  name='ip'
                  control={control}
                  rules={{
                    required: { value: true, message: "Заполните" },
                    pattern: {
                      value: ipPattern,
                      message: "Некорректный формат",
                    },
                  }}
                  render={({ field }) => <RedditTextField {...field} />}
                />

                <ErrorMessage
                  errors={errors}
                  name='ip'
                  render={({ message }) => (
                    <p className='lteFormError'>{message}</p>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Label>aciz</Label>

                <Controller
                  name='aciz'
                  control={control}
                  render={({ field }) => <RedditTextField {...field} />}
                />
              </Grid>

              <Grid item lg={8} xs={12}>
                <Label>Адрес</Label>
                <Controller
                  name='address'
                  control={control}
                  rules={{ required: { value: true, message: "Заполните" } }}
                  render={({ field }) => <RedditTextField {...field} />}
                />

                <ErrorMessage
                  errors={errors}
                  name='address'
                  render={({ message }) => (
                    <p className='lteFormError'>{message}</p>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Контакт</Label>

                <Controller
                  name='contact'
                  control={control}
                  render={({ field }) => <RedditTextField {...field} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Комментарий</Label>

                <Controller
                  name='comment'
                  control={control}
                  render={({ field }) => <RedditTextField {...field} />}
                />
              </Grid>

              <Grid
                item
                container
                spacing={2}
                style={{ alignItems: "flex-end" }}
              >
                <Grid item sm={3} xs={12}>
                  <CustButton
                    fullWidth
                    onClick={handleDiagnostic}
                    endIcon={diagLoading ? <FacebookCircularProgress /> : ""}
                  >
                    Диагностика
                  </CustButton>
                  {diagnosticError && (
                    <p className='lteFormError'>
                      Заполните поля Логин, Пароль, IP
                    </p>
                  )}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Label>Логин</Label>

                  <Controller
                    name='login'
                    control={control}
                    render={({ field }) => <RedditTextField {...field} />}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Label>Пароль</Label>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field }) => (
                      <RedditTextField type='password' {...field} />
                    )}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <CustButton
                    style={{
                      height: 40,
                    }}
                    // onClick={handleSave}
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading}
                    endIcon={loading ? <FacebookCircularProgress /> : ""}
                  >
                    Сохранить
                  </CustButton>
                </Grid>

                {/* {diagnosticError ? (
                  <Grid item>
                    <Typography color='red'>
                      Заполните поля Логин, Пароль, IP
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )} */}

                {lte.lteDiagnostic?.available ? (
                  <>
                    <Grid item container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <Label>Устройство</Label>
                        <RedditTextField value={lte.lteDiagnostic.name} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Label>Версия</Label>
                        <RedditTextField value={lte.lteDiagnostic.version} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Label>Модель</Label>
                        <RedditTextField value={lte.lteDiagnostic.model} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Label>Время работы</Label>
                        <RedditTextField
                          value={handleUptime(lte.lteDiagnostic.uptime)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container>
                      <Grid item md={8} xs={12}>
                        <LteDiagram
                          interfaces={Object.values(
                            lte.lteDiagnostic.interfaces
                          )}
                          bridges={lte.lteDiagnostic.bridges}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <LteTable hosts={lte.lteDiagnostic.hosts} />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Layout>
  );
}

LteCheckForm.propTypes = {
  dispatch: pt.func,
  lte: pt.object,
  ping: pt.string,
  loading: pt.bool,
  diagLoading: pt.bool,
  loadingPing: pt.bool,
};

const mapStateTopProps = (state) => {
  return {
    lte: state.lteCheckReducers.lte,
    loading: state.lteCheckReducers.loadingById,
    ping: state.lteCheckReducers.ping,
    diagLoading: state.lteCheckReducers.diagLoading,
    loadingPing: state.lteCheckReducers.loadingPing,
  };
};

export default connect(mapStateTopProps)(LteCheckForm);
