import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import ReactTable from '../ReactTable/ReactTable';
import {
  getClientsReport,
  clientsReportCsv,
  clearCsvFile,
} from '../../redux/actions/clientsReportActions';
import { clientsReportColumns } from './table/clientsReportColumns';
import moment from 'moment';

const initialDate = (type) => {
  if (type === 'from') {
    return moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  } else {
    return moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
  }
};

function ClientsReport({ dispatch, reports }) {
  const [fromDate, setFromDate] = useState(
    sessionStorage.getItem('fromDate') || initialDate('from')
  );
  const [toDate, setToDate] = useState(
    sessionStorage.getItem('toDate') || initialDate
  );

  const [loading, setLoading] = useState(false);

  const { csvLoading } = useSelector(
    (state) => state.clientsReportReducers.csv
  );

  const handleUpdate = () => {
    sessionStorage.setItem('fromDate', fromDate);
    sessionStorage.setItem('toDate', toDate);
    setLoading(true);
    dispatch(getClientsReport({ fromDate, toDate }, setLoading));
  };

  const handleCsv = () => {
    dispatch(clientsReportCsv({ fromDate, toDate }));
  };

  useEffect(() => {
    return () => dispatch(clearCsvFile());
  }, []);

  // console.log(fromDate, toDate);

  return (
    <ReactTable
      data={reports}
      columns={clientsReportColumns}
      dates={{ fromDate, toDate }}
      setFromDate={setFromDate}
      setToDate={setToDate}
      handleUpdate={handleUpdate}
      csv={true}
      handleCsv={handleCsv}
      csvLoading={csvLoading}
      loading={loading}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    reports: state.clientsReportReducers.reports,
  };
};

export default connect(mapStateToProps)(ClientsReport);
