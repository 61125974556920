import React, { useMemo, useState } from 'react';
import './reactTable.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Card,
  CardContent,
  TableContainer,
  Grid,
  Container as MuiContainer,
} from '@mui/material';

import { GrDocumentCsv } from 'react-icons/gr';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux/es/exports';

import { GlobalFilter } from './Filtering/GlobalFilter';
import ColumnsCheck from './ColumnsCheck/ColumnsCheck';
import { Modal } from './Modal/Modal';
import {
  CustButton,
  FacebookCircularProgress,
  TimePicker,
} from '../customComponents/ReuseComponents';
import { UserModal } from '../Users/Modal/Modal';
import Layout from '../../Layout/Layout';
import { Link } from 'react-router-dom';
import MyAutocomplete from '../Autocomplete/Autocomplete';
import { clearCsvFile } from '../../redux/actions/clientsReportActions';

const useStyles = makeStyles({
  card: {
    borderRadius: '0 !important',
    transition: '0.3s',
    boxShadow: 'none !important',
    border: '1px solid #e0e0e0',
  },
});

const Container = styled(MuiContainer)`
  @media (min-width: 1300px) {
    width: 1300px;
  }
`;

function ReactTable({
  columns,
  data,
  setData,
  dates = {},
  setFromDate,
  setToDate,
  loading,
  clientLoading,
  clientDateStart,
  clientDateEnd,
  setClientDateStart,
  setClientDateEnd,
  stateId,
  setStateId,
  clientDetails = false,
  handleUpdate,
  isEditColumn,
  clientHandleUpdate,
  clientsOn = false,
  array,
  modal,
  setModal,
  addButton,
  csv = false,
  csvLoading = false,
  handleCsv,
  isUserPage = false,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const memoColumns = useMemo(() => columns, []);
  const memoData = useMemo(() => data, [data]);
  const { fileName } = useSelector((state) => state.clientsReportReducers.csv);
  const dispatch = useDispatch();

  const handleClearCsv = () => {
    setTimeout(() => dispatch(clearCsvFile()), 1000);
  };

  const tableInstance = useTable(
    {
      columns: memoColumns,
      data: memoData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = tableInstance;

  const { globalFilter } = state;
  const [row, setRow] = useState({});

  const handleClickOpen = (row) => {
    setRow(row);
    setOpen(true);
  };

  const handleUserPage = (row) => {
    setRow(row);
    setModal(true);
  };

  return (
    <Layout>
      <Container maxWidth='xl'>
        {open && (
          <Modal open={open} setOpen={setOpen} row={row} setRow={setRow} />
        )}

        {modal && (
          <UserModal
            open={modal}
            setOpen={setModal}
            user={row}
            setUser={setRow}
          />
        )}

        <Box>
          <Card className={classes.card}>
            <CardContent
              className={classes.content}
              style={{
                display: 'flex',
                padding: '7px 10px',
                alignItems: 'center',
              }}
            >
              <Grid container spacing={1}>
                <Grid item style={{ display: 'flex' }}>
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                  <ColumnsCheck
                    columns={allColumns}
                    toggleAll={getToggleHideAllColumnsProps}
                  />

                  {Object.keys(dates).length || clientDetails ? (
                    <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
                      <TimePicker
                        onChange={(e) => {
                          clientDetails
                            ? setClientDateStart(e.target.value)
                            : setFromDate(e.target.value);
                        }}
                        variant='filled'
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type='date'
                        defaultValue={
                          clientDetails ? clientDateStart : dates.fromDate
                        }
                        height={40}
                      />
                      <Box ml={1} style={{ display: 'inline-block' }}>
                        <TimePicker
                          onChange={(e) => {
                            clientDetails
                              ? setClientDateEnd(e.target.value)
                              : setToDate(e.target.value);
                          }}
                          variant='filled'
                          InputProps={{
                            disableUnderline: true,
                          }}
                          type='date'
                          defaultValue={
                            clientDetails ? clientDateEnd : dates.toDate
                          }
                          height={40}
                        />
                      </Box>
                      <Box ml={1}>
                        <CustButton
                          disabled={loading}
                          onClick={
                            clientDetails ? clientHandleUpdate : handleUpdate
                          }
                          endIcon={
                            loading || clientLoading ? (
                              <FacebookCircularProgress />
                            ) : (
                              ''
                            )
                          }
                        >
                          Обновить
                        </CustButton>
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>

                {csv && (
                  <>
                    <Grid item>
                      <Box ml={1}>
                        <CustButton
                          disabled={loading}
                          endIcon={
                            csvLoading ? (
                              <FacebookCircularProgress />
                            ) : (
                              <GrDocumentCsv style={{ fontSize: 15 }} />
                            )
                          }
                          onClick={handleCsv}
                          style={{ minWidth: 94 }}
                        >
                          CSV
                        </CustButton>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box ml={1}>
                        <a
                          onClick={handleClearCsv}
                          href={`/fileloader/${fileName}`}
                          download
                        >
                          {fileName}
                        </a>
                      </Box>
                    </Grid>
                  </>
                )}

                {addButton && (
                  <Grid item>
                    <CustButton component={Link} to='/lte-add'>
                      Добавить точку
                    </CustButton>
                  </Grid>
                )}

                {clientsOn ? (
                  <>
                    <Grid item>
                      <Box>
                        <CustButton
                          disabled={loading}
                          onClick={handleUpdate}
                          style={{
                            height: 40,
                          }}
                          endIcon={loading ? <FacebookCircularProgress /> : ''}
                        >
                          Обновить
                        </CustButton>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {clientDetails || clientsOn ? (
          <MyAutocomplete
            stateId={stateId}
            setStateId={setStateId}
            array={array}
          />
        ) : (
          ''
        )}

        <TableContainer
          sx={{
            minHeight: clientDetails || clientsOn ? '71.5vh' : '76vh',
            maxHeight: clientDetails || clientsOn ? '71.5vh' : '76vh',
            border: '1px solid #e0e0e0',
            borderBottom: 'none',
            borderTop: 'none',
          }}
        >
          <Table className='react-table' stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <TableCell
                      key={i}
                      {...column.getSortByToggleProps({ title: 'Сортировка' })}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width ? column.width : 'auto',
                        },
                      })}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className='fa-solid fa-arrow-down'></i>
                          ) : (
                            <i className='fa-solid fa-arrow-down rotate'></i>
                          )
                        ) : (
                          <i className='fa-solid fa-arrow-down turn-off'></i>
                        )}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return isEditColumn && row.cells.length - 1 === i ? (
                        <TableCell
                          {...cell.getCellProps()}
                          align='right'
                          onClick={() =>
                            isUserPage
                              ? handleUserPage(row.original)
                              : handleClickOpen(row.original)
                          }
                          style={{
                            width: 70,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      ) : (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width
                                ? cell.column.width
                                : 'auto',
                            },
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Card className={classes.card}>
          <CardContent style={{ padding: 15 }}></CardContent>
        </Card>
      </Container>
    </Layout>
  );
}

export default ReactTable;
