import * as React from "react";
import { Paper as MuiPaper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { BootstrapInput } from "../customComponents/ReuseComponents";

const ClientSelect = styled(BootstrapInput)({
  width: "100%",
  "& .MuiInputBase-input": {
    textAlign: "center",
    padding: "5px",
    borderRadius: 0,
    borderTop: "1px solid white",
  },
});

const Paper = styled(MuiPaper)({
  borderRadius: 0,
  marginTop: 4,
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
  border: "1px solid #e0e0e0",
  fontSize: 14,
});

const CustomPaper = (props) => {
  return <Paper {...props} />;
};

export default function MyAutocomplete({ array, setStateId, stateId }) {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (array.length) {
      const index = array.findIndex((elem) =>
        elem.clientId_n ? elem.clientId_n == stateId : elem.id == stateId
      );
      setSelected(array[index]);
    }
  }, [array, stateId]);

  const [inputValue, setInputValue] = React.useState("");

  console.log(array);

  return (
    <div>
      <Autocomplete
        value={selected}
        onChange={(event, newValue) => {
          setSelected(newValue);
          newValue.clientID
            ? setStateId(newValue.clientId_n)
            : setStateId(newValue.id);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) =>
          option.address
            ? `${option.name} [${option.address}]`
            : option.name || ""
        }
        renderOption={(props, option) => {
          return option?.address ? (
            <li style={{ fontSize: 14 }} {...props} key={option.id}>
              {option.address
                ? `${option.name} [${option.address}]`
                : option.name}
            </li>
          ) : (
            <li style={{ fontSize: 14 }} {...props} key={option.clientId_n}>
              {option.name}
            </li>
          );
        }}
        noOptionsText="Не найдено"
        PaperComponent={CustomPaper}
        options={array}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <ClientSelect {...params.inputProps} />
          </div>
        )}
      />
    </div>
  );
}
