import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Grid, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  FacebookCircularProgress,
  IOSSwitch,
  Label,
  RedditTextField,
} from '../../customComponents/ReuseComponents';
import { userEdit, getRoleList } from '../../../redux/actions/userActions';

export const UserModal = ({ open, setOpen, user, setUser }) => {
  const closeModal = useSelector((state) => state.userReducers.closeModal);
  const { userRoles, loading } = useSelector((state) => state.userReducers);

  const [rolesObject, setRolesObject] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (userRoles?.length) {
      const obj = userRoles.reduce(
        (a, v) => ({ ...a, [v]: user.roles.includes(v) ? true : false }),
        {}
      );
      setRolesObject(obj);
    }
  }, [userRoles]);

  useEffect(() => {
    if (!userRoles?.length) {
      dispatch(getRoleList());
    }
  }, []);

  useEffect(() => {
    if (closeModal === 'close') {
      handleClose();
    }
    dispatch({ type: 'CHANGE_RESET' });
  }, [closeModal]);

  const handleClose = () => {
    setOpen(false);
    setUser({});
  };

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleRoles = (e, key) => {
    setRolesObject({ ...rolesObject, [key]: e.target.checked });
  };

  const handleUserEdit = () => {
    const convertToArr = [];
    for (let key in rolesObject) {
      if (rolesObject[key] === true) convertToArr.push(key);
    }

    dispatch(userEdit({ ...user, roles: convertToArr }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleUserEdit();
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <BootstrapDialogTitle onClose={handleClose}>
          Пользователь
        </BootstrapDialogTitle>
        <DialogContent dividers onKeyPress={handleKeyPress}>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <Label>Логин:</Label>
              <RedditTextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                inputProps={{ readOnly: true }}
                value={user.username || ''}
              />
            </Grid>
            <Grid item>
              <Label>Фамилия:</Label>
              <RedditTextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                name="lastName"
                onChange={handleChange}
                fullWidth
                value={user.lastName || ''}
              />
            </Grid>
            <Grid item>
              <Label>Имя:</Label>
              <RedditTextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                name="firstName"
                onChange={handleChange}
                fullWidth
                value={user.firstName || ''}
              />
            </Grid>
            <Grid item>
              <Label>Статус:</Label>
              <Box
                style={{
                  border: '1px solid #e0e0e0',
                  borderRadius: 4,
                  padding: '0 7px',
                }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="enabled"
                      sx={{ m: 1 }}
                      checked={user.active}
                      onChange={(e) =>
                        setUser({ ...user, active: e.target.checked })
                      }
                    />
                  }
                  label={user.active ? 'Активен' : 'Неактивен'}
                />
              </Box>
            </Grid>
            <Grid item>
              <Label>Роли:</Label>
              {Object.values(rolesObject).length ? (
                <Box
                  style={{
                    border: '1px solid #e0e0e0',
                    borderRadius: 4,
                    padding: '0 7px',
                  }}
                >
                  {userRoles.map((role) => (
                    <FormControlLabel
                      key={role}
                      control={
                        <Checkbox
                          checked={rolesObject[role]}
                          onChange={(e) => handleRoles(e, role)}
                          size="small"
                        />
                      }
                      label={role}
                    />
                  ))}
                </Box>
              ) : (
                <FacebookCircularProgress />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'center', padding: 0 }}
        >
          <Button
            onClick={handleUserEdit}
            endIcon={loading ? <FacebookCircularProgress /> : ''}
            color="primary"
            style={{
              width: '100%',
              height: 48,
            }}
          >
            Подтвердить
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
