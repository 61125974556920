import moment from "moment";
import "moment/locale/ru";
import { numberFormat } from "../../../helpers/helpers";

export const clientColumns = [
  {
    id: "dateOfcall",
    Header: "Дата",
    accessor: (cell) =>
      moment(cell.dateOfCall).locale("ru").format("DD MMM YYYY г. hh:mm"),
  },
  {
    Header: "Направление",
    accessor: "codeId.destination",
  },
  {
    Header: "Вызывающий номер",
    accessor: "calledNumber",
  },
  {
    Header: "Вызываемый номер",
    accessor: "callDestination",
  },
  {
    Header: "Длительность",
    accessor: "duration",
  },
  {
    Header: "Стоимость",
    accessor: "callCharge",
    Cell: ({ value }) => numberFormat(value),
  },
];
