import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import pt from "prop-types";
import {
  Container,
  Grid,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  CardContent,
} from "@mui/material";
import {
  BootstrapInput,
  Card,
  CustButton,
  FacebookCircularProgress,
  Label,
} from "../customComponents/ReuseComponents";
import styled from "styled-components";
import { spacing } from "@mui/system";
import Layout from "../../Layout/Layout";
import { clientsMove, getSwitchList } from "../../redux/actions/switchActions";

const Select = styled(MuiSelect)(spacing);

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 4,
      boxShadow: "0px 3px 5px #e0e0e0",
      border: "1px solid #e0e0e0",
    },
  },
};

const MenuItem = styled(MuiMenuItem)`
  font-size: 14px !important;
`;

function ClientsMove({ switchList, loading, dispatch }) {
  const ports = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [move, setMove] = useState({
    oldSwitch: 0,
    newSwitch: 1,
    oldVlan: ports[0],
    newVlan: ports[1],
  });

  useEffect(() => {
    if (!switchList.length) {
      dispatch(getSwitchList());
    }
  }, []);

  useEffect(() => {
    if (switchList.length) {
      setMove({
        ...move,
        oldSwitch: switchList[0].id,
        newSwitch: switchList[1].id,
      });
    }
  }, [switchList]);

  const handleSubmit = () => {
    const oldName = switchList.filter((s) => s.id === move.oldSwitch)[0].name;
    const newName = switchList.filter((s) => s.id === move.newSwitch)[0].name;

    if (
      window.confirm(
        `Вы уверены, что хотите перенести клиентов с коммутатора ${oldName} с порта ${move.oldVlan} на коммутатор ${newName} на порт ${move.newVlan} ?`
      )
    ) {
      dispatch(clientsMove(move));
    }
  };

  const handleChange = (e) => {
    setMove({
      ...move,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={10} xs={12}>
                    <Label>Перенести с:</Label>
                    <Select
                      fullWidth
                      MenuProps={MenuProps}
                      input={<BootstrapInput />}
                      name="oldSwitch"
                      value={move.oldSwitch}
                      onChange={handleChange}
                    >
                      {switchList.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <Label>Порт:</Label>
                    <Select
                      fullWidth
                      MenuProps={MenuProps}
                      input={<BootstrapInput />}
                      name="oldVlan"
                      value={move.oldVlan}
                      onChange={handleChange}
                    >
                      {ports.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={10} xs={12}>
                    <Label>Перенести на:</Label>
                    <Select
                      fullWidth
                      MenuProps={MenuProps}
                      input={<BootstrapInput />}
                      value={move.newSwitch}
                      name="newSwitch"
                      onChange={handleChange}
                    >
                      {switchList.map((s, i) => (
                        <MenuItem key={i} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <Label>Порт:</Label>
                    <Select
                      fullWidth
                      MenuProps={MenuProps}
                      name="newVlan"
                      value={move.newVlan}
                      input={<BootstrapInput />}
                      onChange={handleChange}
                    >
                      {ports.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CustButton
                      fullWidth
                      onClick={handleSubmit}
                      endIcon={loading ? <FacebookCircularProgress /> : ""}
                    >
                      Перенести
                    </CustButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

ClientsMove.propTypes = {
  switchList: pt.array,
  loading: pt.bool,
};

const mapStateTopProps = (state) => {
  return {
    switchList: state.switchReducers.switches,
    loading: state.switchReducers.clientsMoving,
  };
};

export default connect(mapStateTopProps)(ClientsMove);
