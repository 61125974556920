import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import pt from "prop-types";
import { Navigate } from "react-router-dom";
import { getUserList } from "../../redux/actions/userActions";
import ReactTable from "../ReactTable/ReactTable";
import { userListColumns } from "./table/userListColumns";
import { checkAuth } from "../../redux/actions/authActions";

function Users({ dispatch, userList, isAuth }) {
  const [modal, setModal] = useState(false);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  // useEffect(() => {
  //   if (!isAuth.admin) {
  //     dispatch(checkAuth());
  //   }
  // }, []);

  return (
    <>
      <ReactTable
        columns={userListColumns}
        data={userList}
        isEditColumn={true}
        isUserPage={true}
        stateId={userId}
        setStateId={setUserId}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
}

Users.propTypes = {
  dispatch: pt.func,
  userList: pt.array,
  isAuth: pt.object,
  loading: pt.bool,
};

const mapStateTopProps = (state) => {
  return {
    userList: state.userReducers.userList,
    isAuth: state.authReducers.isAuth,
  };
};

export default connect(mapStateTopProps)(Users);
