import { Chip } from "@mui/material";
import { numberFormat } from "../../../helpers/helpers";

export const clientsOnColumns = [
  {
    Header: "Логин",
    accessor: "login",
    width: 100,
  },
  {
    Header: "Баланс",
    accessor: (cell) =>
      cell.balance < 0 ? (
        <span style={{ color: "#E06D6D" }}>{numberFormat(cell.balance)}</span>
      ) : (
        numberFormat(cell.balance)
      ),
    width: 100,
  },
  {
    Header: "Статус",
    accessor: (cell) => (
      <Chip
        size='small'
        label={cell.blocked ? "Да" : "Нет"}
        color={cell.blocked ? "error" : "success"}
        variant='outlined'
      />
    ),
    width: 70,
  },
  {
    Header: "Тариф",
    accessor: "mainTariff.tariffName",
  },
  {
    Header: "Стоимость",
    accessor: (cell) => numberFormat(cell.mainTariff?.tariffCost),
    width: 100,
  },
  {
    Header: "Имя",
    accessor: "fullName",
    width: 250,
  },
  {
    Header: "Адрес",
    accessor: "actualAddress",
  },
  {
    Header: "Телефон",
    accessor: "mobilePhone",
    width: 100,
  },
];
