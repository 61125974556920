import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const getUserList = () => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.USER_LIST);

    dispatch({
      type: type.USER_LIST,
      payload: data,
    });
  } catch (e) {
    // console.log(e.response, 'error');
  }
};

export const userEdit = (user) => async (dispatch) => {
  try {
    dispatch({ type: type.USER_SAVE_IN_PROGRESS });

    delete user.password;
    const { data } = await axios.post(url.PREFIX + url.SAVE_USER, user);

    dispatch({
      type: type.USER_SAVE,
      payload: data.body,
    });
  } catch (e) {
    console.log(e, 'error');
    dispatch({
      type: type.USER_SAVE_IN_ERROR,
      payload: `${e.response?.data.message}. ${e.response?.status}`,
    });
  }
};

export const changeStatus = (user) => async (dispatch) => {
  try {
    delete user.password;
    dispatch({ type: type.USER_CHANGE_STATUS_IN_PROGRESS });
    dispatch({ type: type.SET_USER, payload: user });

    const { data } = await axios.post(url.PREFIX + url.SAVE_USER, user);

    dispatch({
      type: type.USER_CHANGE_STATUS,
      payload: data.body,
    });
  } catch (e) {
    dispatch({
      type: type.USER_CHANGE_STATUS_IN_ERROR,
      payload: `${e.response?.data?.message}. ${e.response?.status}`,
    });
    // console.log(e.response, 'error');
  }
};

export const getRoleList = () => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.ROLE_LIST);

    dispatch({
      type: type.ROLE_LIST,
      payload: data,
    });
  } catch (e) {
    // console.log(e.response, 'error');
  }
};
