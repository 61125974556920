import React, { useCallback, useState } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  ReactFlowState,
  ReactFlowInstance,
  FitViewOptions,
  NodeChange,
  EdgeChange,
  Connection,
  applyNodeChanges,
  applyEdgeChanges,
  XYPosition,
} from 'react-flow-renderer';
import { green } from '@mui/material/colors';
import { DiagramProps, IBridges } from './Diagram.props';
import './Diagram.css';

// const onInit = (reactFlowInstance) =>
//   console.log("flow loaded:", reactFlowInstance);

const LteDiagram: React.FC<DiagramProps> = ({
  interfaces,
  bridges,
}): JSX.Element => {
  const initEdges: Edge[] = [];

  let xPosition = 155;

  const heightCalc = {};
  const interfaceTypes = ['ether', 'ppp-out', 'sfp'];
  const priorities = {
    ether: 0,
    'ppp-out': 1,
    sftP: 2,
  };

  // 1 блок
  const parent: Node[] = interfaces
    .filter((int) => !int.parent && interfaceTypes.includes(int.type))
    .sort((a, b) => priorities[a.type] - priorities[b.type])
    .map((int, i) => {
      return {
        id: int.name,
        type: 'input',
        data: {
          label: <>{int.name}</>,
        },
        draggable: false,
        connectable: false,
        style: {
          background: int.state ? green[500] : 'gray',
          color: 'white',
        },
        position: {
          x: xPosition * i,
          y: 35,
        },
      };
    });

  const children: Node[] = [];
  // 2 блок children
  const temp = interfaces.filter((int) => int.parent);

  temp.forEach((int, i) => {
    if (heightCalc[int.parent]) {
      heightCalc[int.parent] = [...heightCalc[int.parent], int.name];
    } else {
      heightCalc[int.parent] = [int.name];
    }

    interface PositionAndParentNode {
      position: XYPosition;
      parentNode: string;
    }

    const calcPositionAndParentNode = (): PositionAndParentNode => {
      let remainder: number = heightCalc[int.parent].length % 2;
      let children: number = heightCalc[int.parent].length;
      let position: XYPosition = { x: 0, y: 0 };
      let parentNode: string = '';

      if (temp.length === 1) {
        parentNode = int.parent;
        position.y = 90;
        return { position, parentNode };
      }

      if (children === 1) {
        parentNode = int.parent;
        position.x = -80;
        position.y = 90;
        return { position, parentNode };
      }

      if (children === 2) {
        parentNode = int.parent;
        position.x = 80;
        position.y = 90;
        return { position, parentNode };
      }

      if (remainder === 0 || remainder !== 0) {
        parentNode = temp[i - 2].name;
        position.y = 70;
        return { position, parentNode };
      }
    };

    const type = bridges.findIndex(
      (bridge) => bridge.interfaceName === int.name
    );

    const node: Node = {
      id: int.name,
      type: type >= 0 ? 'default' : 'output',
      data: {
        label: <>{int.name}</>,
      },
      style: {
        background: int.state ? green[500] : 'gray',
        color: 'white',
      },
      parentNode: calcPositionAndParentNode().parentNode,
      position: calcPositionAndParentNode().position,
    };
    const edge: Edge = {
      id: `${int.parent}-${int.name}`,
      // type: "straight",
      source: int.parent,
      target: int.name,
    };

    initEdges.push(edge);

    children.push(node);
  });

  const bridg: IBridges = {};

  bridges.forEach((bridge) => {
    if (bridg[bridge.bridgeName]) {
      bridg[bridge.bridgeName].relations.push(bridge.interfaceName);
    } else {
      bridg[bridge.bridgeName] = {
        ...bridge,
        relations: [bridge.interfaceName],
      };
    }
  });

  const obj = {};

  // 3
  const bridgesNode: Node[] = Object.values(bridg).map((bridge, i) => {
    const node: Node = {
      id: `${bridge.bridgeName}`,
      // type: bridge.interfaceName.includes("out") ? "input" : "output",
      position: { x: xPosition * i, y: 300 },
      data: {
        label: <>{bridge.bridgeName}</>,
      },
      style: {
        background: interfaces.find((int) => int.name === bridge.bridgeName)
          .state
          ? green[500]
          : 'gray',
        color: 'white',
      },
    };

    bridge.relations.forEach((relation) => {
      const source = relation.includes('out');
      const edge: Edge = {
        id: `${relation}-${bridge.bridgeName}`,
        source: source ? `${bridge.bridgeName}` : relation,
        target: !source ? `${bridge.bridgeName}` : relation,
      };

      initEdges.push(edge);

      if (source) {
        obj[relation] = bridge.bridgeName;
      }
    });

    return node;
  });

  let num = Object.keys(obj).length * xPosition;

  const logicalInterface: Node[] = interfaces
    .filter((int) => int.type === 'l2tp-out')
    .map((int) => {
      let x = 0;
      if (!obj[int.name]) {
        x = num;
        num += xPosition;
      }

      const node: Node = {
        id: int.name,
        type: 'output',
        position: obj[int.name] ? { x: 0, y: 100 } : { x, y: 400 },
        parentNode: obj[int.name],
        data: {
          label: <>{int.name}</>,
        },
        style: {
          background: int.state ? green[500] : 'gray',
          color: 'white',
        },
      };

      return node;
    });

  const initNodes: Node[] = [
    ...parent,
    ...children,
    ...bridgesNode,
    ...logicalInterface,
  ];

  const [nodes, setNodes] = useState<Node[]>(initNodes);
  const [edges, setEdges] = useState<Edge[]>(initEdges);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <>
      <div style={{ height: 350 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          snapToGrid={true}
          snapGrid={[5, 5]}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          // onInit={onInit}
          fitView
          // fitViewOptions={{ padding: 0.1 }}
          minZoom={0.7}
          maxZoom={0.8}
          attributionPosition="bottom-right"
        >
          {/* <MiniMap
          nodeStrokeColor={(n) => {
            if (n.style?.background) return n.style.background;
            if (n.type === "input") return "#0041d0";
            if (n.type === "output") return "#ff0072";
            if (n.type === "default") return "#1a192b";

            return "#eee";
          }}
          nodeColor={(n) => {
            if (n.style?.background) return n.style.background;

            return "#fff";
          }}
          nodeBorderRadius={2}
        /> */}
          {/* <Controls /> */}
          <Background color="#aaa" gap={13} />
        </ReactFlow>
      </div>
      {/* <div style={{ width: "30%", padding: 15, border: "1px solid gray" }}>
        HOSTS HOSTS HOSTS
      </div> */}
    </>
  );
};

export default LteDiagram;
