import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const getFails = () => async (dispatch) => {
  try {
    dispatch({ type: type.GET_FAILS_IN_PROGRESS });

    const { data } = await axios.post(url.PREFIX + url.GET_FAILS);

    dispatch({
      type: type.GET_FAILS,
      payload: data,
    });
  } catch (e) {
    dispatch({ type: type.GET_FAILS_IN_ERROR });

    console.log(e.response, 'error');
  }
};
