import React from "react";
import { Link } from "react-router-dom";
import { Chip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { store } from "../../../App";
import { removeLteById } from "../../../redux/actions/lteCheckActions";
import { useDispatch, useSelector } from "react-redux";
import { FacebookCircularProgress } from "../../customComponents/ReuseComponents";

let id = 0;

const RemoveCell = ({ value, row }) => {
  const dispatch = useDispatch();
  const { loadingRemove: loading } = useSelector(
    (state) => state.lteCheckReducers
  );

  const handleClick = () => {
    if (
      window.confirm(
        `Вы уверенны что хотите удалить устройство с IP ${row.original.ip}`
      )
    ) {
      dispatch(removeLteById(row.original.id));
      id = row.original.id;
    }
  };

  return (
    <span style={{ display: "flex", justifyContent: "center" }}>
      {loading && id == row.original.id ? (
        <FacebookCircularProgress />
      ) : (
        <IconButton style={{ padding: 10 }} onClick={handleClick}>
          <FontAwesomeIcon icon={faTrash} fontSize={18} />
        </IconButton>
      )}
    </span>
  );
};

export const lteListColumns = [
  {
    Header: "Пинг",
    accessor: (row) => (
      <Chip
        size='small'
        color={row.ping ? "success" : "error"}
        variant='outlined'
        label={String(row.ping)}
      />
    ),
    width: 80,
  },
  {
    Header: "Инфо",
    accessor: (row) => (
      <Chip
        size='small'
        color={row.info ? "success" : "error"}
        variant='outlined'
        label={String(row.info)}
      />
    ),
    width: 80,
  },
  {
    Header: "Район",
    accessor: "district",
    width: 200,
  },
  {
    Header: "Адрес",
    accessor: "address",
    width: 200,
  },
  {
    Header: "IP",
    accessor: "ip",
  },
  {
    Header: "vlans",
    accessor: "vlans",
    width: 80,
  },
  {
    Header: "contact",
    accessor: "contact",
  },
  {
    id: "remove-button",
    Cell: RemoveCell,
    width: 40,
  },
  {
    id: "edit-button",
    Cell: (cell) => (
      <Link
        to={`/lte-edit/${cell.row.original.id}`}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton style={{ padding: 10 }}>
          <FontAwesomeIcon icon={faPenToSquare} fontSize={18} />
        </IconButton>
      </Link>
    ),
    width: 40,
  },
];
