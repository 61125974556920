import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  clientData: {},
  answer: {
    key: '',
  },
  promisePay: {},
  sendPassword: '',
  answerError: '',
  canClose: false,
  abonentCloseModal: 'default',
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.GET_ABONENTS:
      return {
        ...state,
        clientData: payload,
        // clientData: {
        //   client: payload.client,
        //   clientList: payload.clientList,
        //   params: payload.params,
        //   diagnostic: payload.diagnostic,
        // },
      };
    case types.CREATE_ISSUE:
      return {
        ...state,
        canClose: true,
        answer: { ...state.answer, ...payload },
      };
    case types.CREATE_ISSUE_ERROR:
      return {
        ...state,
        answer: { key: '' },
        answerError: payload,
      };
    case types.SEND_PASSWORD:
      return {
        ...state,
        canClose: true,
        sendPassword: payload,
      };
    case types.CREATE_PROMISE_PAY:
      return {
        ...state,
        canClose: true,
        promisePay: { ...payload },
      };
    case types.REBOOT_ONT:
      toast.success('Успешно перезагружено');
    case types.REBOOT_ONT_ERROR:
      toast.error(payload);
    case 'CLEAR_ISSUE':
      return {
        ...state,
        canClose: false,
        answerError: '',
        answer: { key: '' },
        sendPassword: '',
      };
    // case "CLEAR_ABONENT":
    //   return {
    //     ...state,
    //     clientData: { client: {}, clientList: [], params: {}, diagnostic: {} },
    //   };
    case 'CLEAR_ABONENT':
      return {
        ...state,
        clientData: {},
      };
    case 'ABONENT_MODAL_DONE':
      return { ...state, abonentCloseModal: 'close' };
    case 'ABONENT_MODAL_RESET': {
      return { ...state, abonentCloseModal: 'default' };
    }

    default:
      return state;
  }
}
