import React from "react";
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
} from "@mui/material";
import { LteTableProps } from "./LteTable.props";

const LteTable: React.FC<LteTableProps> = ({ hosts }): JSX.Element => {
  const heads = {
    bridge: "Бридж",
    mac: "Мак",
    onInterface: "Интерфейс",
  };

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {Object.values(heads).map((head) => (
              <TableCell style={{ padding: "6px 8px" }}>{head}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {hosts
            .filter((host) => !host.local)
            .map((host, i) => {
              return (
                <TableRow key={i}>
                  {Object.keys(heads).map((head) => (
                    <TableCell style={{ padding: "6px 8px" }}>
                      {host[head]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LteTable;
