import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';
import { toast } from 'react-toastify';

const nonAuth = ['/findPosition'];

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (!nonAuth.includes(window.location.pathname)) {
        window.location = '/login';
      }
    } else if (error.response.status === 403) {
      accessForbidden();
    } else {
      return Promise.reject(error);
    }
  }
);

export const accessForbidden = () => {
  toast.warn(
    <span>
      Нет доступа к ресурсу <br /> (код ошибки 403)
    </span>,
    {
      position: 'top-right',
      autoClose: 12000,
      closeOnClick: true,
      pauseOnHover: true,
    }
  );
};

export const checkAuth = () => async (dispatch) => {
  try {
    const { data } = await axios.post(url.PREFIX + url.CHECK_AUTH);

    dispatch({
      type: type.CHECK_AUTH,
      payload: data,
    });
  } catch (e) {
    console.log(e.response, 'error');
  }
};

export const signIn = (user, navigate) => async (dispatch) => {
  try {
    // const formData = new FormData();
    // formData.append('username', user.username);
    // formData.append('password', user.password);

    const promise = fetch(url.SIGN_IN, {
      method: 'POST',
      headers: new Headers({
            'Authorization': 'Basic ' + btoa(user.username+':'+user.password)
          }),
        redirect: 'manual',
    });

    promise.then(async () => {
      const { data } = await axios.post(url.PREFIX + url.CHECK_AUTH);
      if (Object.keys(data).length) {
        navigate('/');
      }
    });
  } catch (e) {
    dispatch({
      type: 'SIGN_IN_ERROR',
    });
    console.log(e);
  }
};
