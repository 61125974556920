import axios from "axios";
import * as url from "../../constants/urls";
import * as type from "../../constants/redux";

export const getTaskList = (setLoading) => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.GET_TASK_LIST);

    // console.log(data);

    dispatch({
      type: type.GET_TASK_LIST,
      payload: data,
    });

    setLoading(false);
  } catch (e) {
    console.log(e.response, "error");
  }
};

export const taskChecking = (setCheckState) => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.TASK_CHECKING);

    const arr = Object.values(data);
    arr.sort((a, b) => {
      let dateA = new Date(a.startAt);
      let dateB = new Date(b.startAt);
      return dateA - dateB;
    });

    dispatch({
      type: type.TASK_CHECKING,
      payload: arr,
    });

    setCheckState(false);
  } catch (e) {
    console.log(e.response, "error");
  }
};

export const getResult = (id) => async (dispatch) => {
  try {
    dispatch({ type: "CLEAR_TASK_RESULT" });

    const { data } = await axios(url.PREFIX + url.GET_RESULT + `?taskId=${id}`);

    console.log(data);

    if (data.success) {
      dispatch({
        type: type.GET_RESULT,
        payload: { ...data, taskId: id },
      });
    } else {
      dispatch({
        type: type.GET_RESULT_IN_ERROR,
        payload: data.error,
      });
    }
  } catch (e) {
    dispatch({
      type: type.GET_RESULT_IN_ERROR,
      payload: e.response.data.message,
    });
    console.log(e.response);
  }
};

export const taskStart = (taskObj) => async (dispatch) => {
  try {
    if (taskObj.params) {
      const formData = new FormData();
      formData.append("taskType", taskObj.key);

      taskObj.params.forEach((param) => {
        if (param.type === "select") {
          formData.append(param.name, taskObj.options[param.name]);
        } else {
          formData.append(param.name, taskObj[param.name]);
        }
      });

      const { data } = await axios.post(taskObj.executor, formData);
      // console.log(data, taskObj);
      dispatch({
        type: type.ADD_TASK,
        payload: { ...data, entity: { name: taskObj.name } },
      });
    } else {
      const { data } = await axios(taskObj.executor);
      dispatch({
        type: type.ADD_TASK,
        payload: { ...data, entity: { name: taskObj.name } },
      });
    }
  } catch (e) {
    dispatch({
      type: type.ADD_TASK_ERROR,
      payload: `${e.response?.data?.message}. ${e.response?.status}`,
    });
    // console.log(e.response, "error");
  }
};
