import { combineReducers } from 'redux';
import switchReducers from './switchReducers';
import abonentReducers from './abonentReducers';
import clientsReportReducers from './clientsReportReducers';
import invoiceReducers from './invoiceReducers';
import authReducers from './authReducers';
import backgroundTaskReducers from './backgroundTaskReducers';
import clientsOnReducers from './clientsOnReducers';
import userReducers from './userReducers';
import lteCheckReducers from './lteCheckReducers';
import failsReducer from './failsReducer';

export const rootReducer = combineReducers({
  switchReducers,
  abonentReducers,
  clientsReportReducers,
  invoiceReducers,
  authReducers,
  backgroundTaskReducers,
  clientsOnReducers,
  userReducers,
  lteCheckReducers,
  failsReducer,
});
