import axios from "axios";
import * as url from "../../constants/urls";
import * as type from "../../constants/redux";

export const getClientsReport =
  ({ fromDate, toDate }, setLoading) =>
  async (dispatch) => {
    try {
      const { data } = await axios(
        url.GET_CLIENTS_REPORT + `/${fromDate}/${toDate}`
      );

      setLoading(false);

      dispatch({
        type: type.GET_CLIENTS_REPORT,
        payload: data,
      });
    } catch (e) {
      // console.log(e.response, "error");
    }
  };

export const getClientList = (setLoading) => async (dispatch) => {
  try {
    const { data } = await axios(url.PREFIX + url.CLIENT_LIST);

    // console.log("clientList", data);

    setLoading(false);

    dispatch({
      type: type.CLIENT_LIST,
      payload: data,
    });
  } catch (e) {
    // console.log(e.response, "error");
  }
};

export const getClientDetail =
  (dateStart, dateEnd, clientId, setLoading) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        url.PREFIX +
          url.GET_CLIENT_DETAIL +
          `?client=${clientId}&dateStart=${dateStart}&dateEnd=${dateEnd}`
      );

      setLoading(false);

      dispatch({
        type: type.GET_CLIENT_DETAIL,
        payload: { data, clientId },
      });
    } catch (e) {
      // console.log(e.response, "error");
    }
  };

export const clientsReportCsv =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: type.GET_CSV,
      });

      const { data } = await axios(
        url.GET_CLIENTS_REPORT + `/${fromDate}/${toDate}?csv=true`
      );

      dispatch({
        type: type.GET_CSV_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: type.GET_CSV_ERROR,
        payload: data,
      });
      // console.log(e.response, "error");
    }
  };

export const clientsDetailReportCsv =
  ({ dateEnd, dateStart, clientId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: type.GET_CSV,
      });

      const { data } = await axios.post(
        url.PREFIX +
          url.GET_CLIENT_DETAIL +
          `?client=${clientId}&dateStart=${dateStart}&dateEnd=${dateEnd}&csv=true`
      );

      dispatch({
        type: type.GET_CSV_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: type.GET_CSV_ERROR,
        payload: data
      });
      // console.log(e.response, "error");
    }
  };

export const clearCsvFile = () => (dispatch) => {
  // console.log("CLAR ACTIONS");
  dispatch({
    type: type.CLEAR_CSV_FILE,
  });
};
