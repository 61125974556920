import React from 'react';
import styles from './FailContainer.module.css';

const FailContainer = ({ children, loading, fails }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Описание</p>
      </div>

      <div className={styles.list}>
        {loading && <h5 className={styles.indicator}>Загрузка</h5>}
        {!loading && !Object.keys(fails).length && (
          <h5 className={styles.indicator}>Пусто</h5>
        )}
        {children}
      </div>
    </div>
  );
};

export default FailContainer;
