import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import pt from 'prop-types';

import { Grid } from '@mui/material';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  FacebookCircularProgress,
  Label,
  RedditTextField,
  TextArea,
} from '../../customComponents/ReuseComponents';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createIssue,
  createPromisePay,
  sendPasswordToPhone,
} from '../../../redux/actions/abonentActions';
import { useSearchParams } from 'react-router-dom';

function AbonentModal({ open, setOpen, client, titles }) {
  const dispatch = useDispatch();
  const { answer, answerError, sendPassword, promisePay, canClose } =
    useSelector((state) => state.abonentReducers);
  const { key } = answer;
  const [message, setMessage] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');

  useEffect(() => {
    if (key) {
      setResult(
        `Обращение зарегистрировано под номером ${key}. Сообщите абоненту номер обращения и переведите вызов на номер 501`
      );
    } else if (answerError) {
      setResult(answerError);
    } else if (promisePay?.error) {
      setResult(promisePay.error);
    } else if (promisePay?.result === 'ok') {
      setResult('Платеж активирован');
    }
  }, [key, answerError, promisePay]);

  const aon = searchParams.get('aon');
  const operator = searchParams.get('operator');

  const handleClose = () => {
    if (!loading) {
      dispatch({ type: 'CLEAR_ISSUE' });
      setResult('');
      setMessage('');
      setOpen(false);
    }
  };

  const handleChangeStatus = () => {
    setLoading(true);
    setResult('');
    if (titles.type === 'call') {
      dispatch(
        createIssue(
          {
            clientId: titles.line !== 0 ? client.id : undefined,
            message,
            done: titles.line === 1 ? true : false,
            aon,
            operator,
          },
          setLoading
        )
      );
    } else if (titles.type === 'password') {
      dispatch(sendPasswordToPhone(client.id, setLoading));
    } else if (titles.type === 'promisePay') {
      dispatch(
        createPromisePay(
          client.id,
          client.basicAccount,
          titles.value,
          setLoading
        )
      );
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth="sm"
        maxWidth="sm"
      >
        <BootstrapDialogTitle onClose={handleClose}>
          {titles.title}
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ maxHeight: 380 }}>
          {titles.type === 'call' ? (
            <Grid container spacing={2} flexDirection="column">
              {titles.line !== 0 && (
                <Grid item>
                  <Label>ФИО:</Label>
                  <RedditTextField
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    gutterBottom
                    inputProps={{ readOnly: true }}
                    value={client?.fullName}
                  />
                </Grid>
              )}

              <Grid item>
                <Label>
                  {canClose
                    ? 'Ответ:'
                    : ' Укажите суть обращения клиента и какая помощь оказана:'}
                </Label>
                {canClose ? (
                  <TextArea
                    value={result && result}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    type="textarea"
                    multiline
                    minRows={5}
                    maxRows={5}
                  />
                ) : (
                  <TextArea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    type="textarea"
                    multiline
                    minRows={5}
                    maxRows={5}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            titles.type === 'password' && (
              <Label>
                {client?.mobilePhone
                  ? `Пароль будет отправлен на номер ${client?.mobilePhone}`
                  : 'Номер не указан'}
              </Label>
            )
          )}
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'center', padding: 0 }}
        >
          <Button
            onClick={() => (!canClose ? handleChangeStatus() : handleClose())}
            color="primary"
            disabled={!client?.mobilePhone && titles.type === 'password'}
            endIcon={loading ? <FacebookCircularProgress /> : ''}
            style={{
              width: '100%',
              height: 48,
            }}
          >
            {loading
              ? 'Подождите'
              : canClose
              ? `Закрыть ${sendPassword ? '(отправлено)' : ''}`
              : titles.submitButton}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

AbonentModal.propTypes = {
  open: pt.bool,
  setOpen: pt.func,
  client: pt.object,
  titles: pt.object,
};

export default React.memo(AbonentModal);
