import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Alert, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeSwitchStatus } from "../../../redux/actions/switchActions";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  FacebookCircularProgress,
  Label,
  RedditTextField,
  TextArea,
  TimePicker,
} from "../../customComponents/ReuseComponents";
import pt from "prop-types";
import { handleDateExpires } from "../../../helpers/helpers";

export const Modal = ({ open, setOpen, row, setRow }) => {
  const { closeModal, statusChanging } = useSelector(
    (state) => state.switchReducers
  );
  const [changeStatus, setChangeStatus] = useState({
    expiredAt: row.problem ? row.problemExpires : "",
    comment: "",
  });
  const handleClose = () => {
    setRow({});
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (closeModal === "close") {
      handleClose();
    }
    dispatch({ type: "CHANGE_RESET" });
  }, [closeModal]);

  const handleChangeStatus = () =>
    dispatch(changeSwitchStatus({ ...changeStatus, id: row.id }));

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChangeStatus();
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth="xs"
        maxWidth="xs"
      >
        <BootstrapDialogTitle onClose={handleClose}>
          Статус
        </BootstrapDialogTitle>
        <DialogContent dividers onKeyPress={handleKeyPress}>
          <Grid container spacing={2} flexDirection="column">
            <Grid item>
              <Label>Коммутатор:</Label>
              <RedditTextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                gutterBottom
                inputProps={{ readOnly: true }}
                value={row.name}
              />
            </Grid>
            <Grid item>
              <Label>
                {row.problem
                  ? "Изменить срок восстановления:"
                  : "Срок восстановления:"}{" "}
              </Label>
              <TimePicker
                onChange={(e) =>
                  setChangeStatus({
                    ...changeStatus,
                    expiredAt: e.target.value,
                  })
                }
                value={changeStatus.expiredAt}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                type="datetime-local"
              />
            </Grid>
            {row.problem ? (
              <Grid item>
                <Alert severity="warning">
                  <strong>Истекает:</strong>{" "}
                  {handleDateExpires(row.problemExpires)}
                </Alert>
              </Grid>
            ) : (
              ""
            )}

            <Grid item>
              <Label>Комментарий:</Label>
              <TextArea
                onChange={(e) =>
                  setChangeStatus({
                    ...changeStatus,
                    comment: e.target.value,
                  })
                }
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                type="textarea"
                multiline
                minRows={3}
                maxRows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "center", padding: 0 }}
        >
          <Button
            onClick={handleChangeStatus}
            color="primary"
            style={{
              width: "100%",
              height: 48,
            }}
            endIcon={statusChanging ? <FacebookCircularProgress /> : ""}
          >
            Изменить статус
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

Modal.propTypes = {
  row: pt.shape({
    id: pt.number.isRequired,
    name: pt.string.isRequired,
    problemExpires: pt.string,
    problem: pt.bool,
  }),
};
