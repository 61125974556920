import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { InputAdornment } from "@mui/material";
import { RedditTextField } from "../../customComponents/ReuseComponents";

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);

  return (
    //  Searching #1
    <RedditTextField
      variant="filled"
      placeholder="Поиск"
      value={value || ""}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" style={{ margin: 0 }}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};
