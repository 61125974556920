import * as types from '../../constants/redux';
import { toast } from 'react-toastify';

const initialState = {
  lteList: [],
  lte: {
    lteDiagnostic: {
      available: false,
    },
  },
  diagLoading: false,
  ping: '',
  loadingPing: false,
  loading: false,
  loadingById: false,
  loadingRemove: false,
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.LTE_LIST:
      return {
        ...state,
        lteList: [...payload].reverse(),
        loading: false,
      };
    case types.GET_LTE_BY_ID:
      return {
        ...state,
        loadingById: false,
        lte: { ...payload, lteDiagnostic: { available: false } },
      };
    case types.SAVE_LTE:
      toast.success('Успешно изменено');
      return {
        ...state,
        lte: { ...payload, lteDiagnostic: { ...state.lte.lteDiagnostic } },
        loadingById: false,
        lteList: state.lteList
          .map((lte) => (lte.id === payload.id ? payload : lte))
          .reverse(),
      };
    case types.ADD_LTE:
      toast.success('Точка добавлена');
      return {
        ...state,
        loadingById: false,
        lteList: [...state.lteList, payload].reverse(),
      };
    case types.LTE_DIAG:
      return {
        ...state,
        diagLoading: false,
        lte: { ...state.lte, lteDiagnostic: payload },
      };
    case types.LTE_DIAG_IN_PROGRESS:
      return {
        ...state,
        diagLoading: true,
        lte: { ...state.lte, lteDiagnostic: { available: false } },
      };
    case types.LTE_DIAG_IN_ERROR:
      toast.error(payload, { autoClose: 8000 });
      return {
        ...state,
        diagLoading: false,
      };
    case types.CHECK_PING:
      return {
        ...state,
        loadingPing: false,
        ping: payload,
      };
    case types.LTE_REMOVE:
      toast.success('Успешно удалено');
      return {
        ...state,
        loadingRemove: false,
        lteList: state.lteList.filter((lte) => lte.id !== payload.id),
      };
    case types.LTE_REMOVE_IN_PROGRESS:
      return {
        ...state,
        loadingRemove: true,
      };
    case types.LTE_REMOVE_IN_ERROR:
      toast.error(payload, { autoClose: 8000 });
      return {
        ...state,
        loadingRemove: false,
      };
    case types.CHECK_PING_IN_PROGRESS:
      return {
        ...state,
        loadingPing: true,
      };
    case types.LTE_LIST_IN_PROGRESS:
      return {
        ...state,
        lte: {
          lteDiagnostic: { available: false },
        },
        loading: true,
      };
    case types.LTE_LIST_IN_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.GET_LTE_BY_ID_LOADING_IN_PROGRESS:
      return {
        ...state,
        loadingById: true,
      };
    case types.GET_LTE_BY_ID_LOADING_IN_ERROR:
      toast.error(payload);
      return {
        ...state,
        loadingById: false,
      };
    case 'CLEAR_LTE':
      return { ...state, lte: {} };
    default:
      return state;
  }
}
