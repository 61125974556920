import React from "react";
import MenuBar from "../components/MenuBar/MenuBar";

function Layout({ children }) {
  return (
    <>
      <MenuBar />
      {children}
    </>
  );
}

export default Layout;
