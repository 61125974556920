import axios from 'axios';
import * as url from '../../constants/urls';
import * as type from '../../constants/redux';

export const getAbonents = (obj, setLoading) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      url.PREFIX +
        url.GET_ABONENTS +
        `?aon=${obj.aon}&operator=${obj.operator}&filter=${obj.filter}`
    );

    dispatch({
      type: 'CLEAR_ABONENT',
    });

    setLoading(false);

    dispatch({
      type: type.GET_ABONENTS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'CLEAR_ABONENT',
    });

    setLoading(false);
    console.log(e.response, 'error');
  }
};

export const createIssue =
  ({ clientId, aon, operator, done, message }, setLoading) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'CLEAR_ISSUE',
      });

      const { data } = await axios(
        url.CREATE_ISSUE +
          `?done=${done}${
            clientId !== undefined ? `&clientId=${clientId}` : ''
          } &aon=${aon}&operator=${operator}&message=${message}`
      );

      setLoading(false);

      dispatch({
        type: type.CREATE_ISSUE,
        payload: data.answer,
      });
    } catch (e) {
      setLoading(false);
      dispatch({
        type: type.CREATE_ISSUE_ERROR,
        payload: e.response.data.message,
      });
      console.log(e.response, 'error');
    }
  };

export const sendPasswordToPhone =
  (clientId, setLoading) => async (dispatch) => {
    try {
      await axios(url.PREFIX + url.SEND_PASSWORD + `?clientId=${clientId}`);

      setLoading(false);

      dispatch({
        type: type.SEND_PASSWORD,
        payload: 'Пароль успешно отправлен',
      });
    } catch (e) {
      setLoading(false);
      console.log(e.response, 'error');
    }
  };

export const createPromisePay =
  (clientId, basicAccount, value = '', setLoading) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        url.CREATE_PROMISE_PAY +
          `?clientId=${clientId}&accountId=${basicAccount}${
            value ? `&value=${value}` : ''
          }`
      );

      setLoading(false);

      dispatch({
        type: type.CREATE_PROMISE_PAY,
        payload: data,
      });
    } catch (e) {
      setLoading(false);
      console.log(e.response, 'error');
    }
  };

export const rebootOnt = (deviceId, pon, ont) => async (dispatch) => {
  try {
    await axios(
      url.PREFIX +
        url.REBOOT_ONT +
        `?deviceId=${deviceId}&pon=${pon}&ont=${ont}`
    );

    dispatch({
      type: type.REBOOT_ONT,
    });
  } catch (e) {
    dispatch({
      type: type.REBOOT_ONT_ERROR,
      payload: e.message,
    });
    console.log(e.message, 'error');
  }
};
