import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  IconButton as MuiIconButton,
  Grid,
  Container,
  Box,
  alpha,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getAbonents } from '../../redux/actions/abonentActions';
import {
  Card,
  CardContent,
  CustButton,
  FacebookCircularProgress,
  HtmlTooltip,
  RedditTextField,
} from '../customComponents/ReuseComponents';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import AbonentResults from './AbonentResults/AbonentResults';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AbonentModal from './Modal/AbonentModal';
import { blue } from '@mui/material/colors';
import pt from 'prop-types';
import Layout from '../../Layout/Layout';

const Label = styled('label')`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

const IconButton = MuiStyled(MuiIconButton)(({ theme }) => ({
  borderRadius: 4,
  minWidth: 45,
  height: 40,
  border: '1px solid #e0e0e0',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),

  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,
  },

  '&:focus': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
  },
}));

const CustomSelect = styled.div`
  font-size: 15px;
  min-height: 110px;
  max-height: 110px;
  border-radius: 4px;
  background: #fcfcfb;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  padding: 5px 10px;
  transition: all 0.3s;

  &:hover {
    background: white;
    border-color: ${blue[500]};
  }

  &:focus {
    background: white;
  }
`;

const CustomOption = styled.span`
  display: block;
  padding: 3px 5px;
  cursor: pointer;
  background: ${(props) => (props.selectedOption ? '#cecece' : 'transparent')};

  &:focus {
    color: white;
    background: ${blue[500]};
  }
`;

function Abonent({ dispatch, clientData, answer }) {
  const [open, setOpen] = useState(false);
  const [titles, setTitles] = useState({
    title: '',
    type: '',
    line: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const aon = searchParams.get('aon');
  const operator = searchParams.get('operator');
  const filter = searchParams.get('filter');

  const [searchByfilter, setSearchByFilter] = useState(
    clientData?.client?.login ? clientData?.client?.login : ''
  );

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const handleSearch = (value = '') => {
    setLoading(true);
    if (value) {
      setSearchByFilter(value);
    }
    dispatch(
      getAbonents({ filter: value.length ? value : searchByfilter }, setLoading)
    );
    setCurrentOption(value.length ? value : searchByfilter);
    navigate(
      `?aon=${aon}&operator=${operator}&filter=${
        value.length ? value : searchByfilter
      }`
    );
  };

  const callResult = (line) => {
    setTitles({
      ...titles,
      title: 'Создать обращение',
      line,
      type: 'call',
      submitButton: 'Отправить',
    });
    setOpen(true);
  };

  useEffect(() => {
    if (aon || operator || filter) {
      if (typeof filter === 'string' && filter.length) {
        setSearchByFilter(filter);
        setCurrentOption(filter);
      }
      setLoading(true);
      dispatch(getAbonents({ aon, operator, filter }, setLoading));
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !loading) {
      handleSearch();
    }
  };

  const handleCustomSelect = (e) => {
    if (e.target.id) {
      setCurrentOption(e.target.id);
      handleSearch(e.target.id);
    }
  };

  const [currentOption, setCurrentOption] = useState(
    clientData?.client?.login ? clientData?.client?.login : 0
  );

  return (
    <Layout>
      <AbonentModal
        setOpen={setOpen}
        open={open}
        answer={answer}
        titles={titles}
        setTitles={setTitles}
      />
      <Container>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={5} item>
                <Label>Номер договора/ телефон/ адрес/ фамилия</Label>

                <Box sx={{ display: 'flex' }}>
                  <RedditTextField
                    fullWidth
                    variant="filled"
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchByFilter(e.target.value)}
                    value={searchByfilter}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{ marginRight: 1 }}
                  />

                  <HtmlTooltip title="Искать">
                    <IconButton
                      disabled={loading}
                      onClick={() => handleSearch()}
                    >
                      {loading ? (
                        <FacebookCircularProgress />
                      ) : (
                        <SearchIcon
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                          }}
                        />
                      )}
                    </IconButton>
                  </HtmlTooltip>
                </Box>

                <CustButton
                  disabled={Boolean(clientData['client']) || loading}
                  onClick={() => callResult(0)}
                  sx={{ marginTop: 2 }}
                  endIcon={
                    <i
                      style={{ fontSize: 14 }}
                      className="fa-solid fa-note-sticky"
                    ></i>
                  }
                  fullWidth
                >
                  Создать тикет
                </CustButton>
              </Grid>

              <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                  <Label>Результаты поиска</Label>
                  <CustomSelect onClick={handleCustomSelect}>
                    {Object.keys(clientData).length
                      ? clientData?.clientList?.map((client) => (
                          <CustomOption
                            tabIndex={0}
                            key={client.id}
                            id={client.login}
                            selectedOption={client.login === currentOption}
                          >
                            {`${client.login} [${client.fullName}] [${client.actualAddress}]`}
                          </CustomOption>
                        ))
                      : ''}
                  </CustomSelect>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {Object.keys(clientData).length ? (
          <AbonentResults clientData={clientData} fadeState={loading} />
        ) : (
          ''
        )}
      </Container>
    </Layout>
  );
}

Abonent.propTypes = {
  dispatch: pt.func,
  clientData: pt.object,
  answer: pt.exact({
    key: pt.string,
  }),
};

const mapStateTopProps = (state) => {
  return {
    clientData: state.abonentReducers.clientData,
    answer: state.abonentReducers.answer,
  };
};

export default connect(mapStateTopProps)(Abonent);
