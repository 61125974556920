import React from "react";

function NotFound() {
  return (
    <>
      <span>NOT FOUND</span>
    </>
  );
}

export default NotFound;
