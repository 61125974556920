import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { CustButton } from '../../../customComponents/ReuseComponents';
import pt from 'prop-types';
import { Typography, useStyles } from '../AbonentResults';
import AbonentModal from '../../Modal/AbonentModal';
import { useDispatch } from 'react-redux';
import { rebootOnt } from '../../../../redux/actions/abonentActions';

const Operations = ({ clientData }) => {
  const { client } = clientData;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [titles, setTitles] = useState({
    title: '',
    type: '',
    submitButton: 'Отправить',
    line: null,
  });

  const callResult = (line) => {
    setTitles({
      ...titles,
      title: 'Создать обращение',
      line,
      type: 'call',
      submitButton: 'Отправить',
    });
    setOpen(true);
  };

  const sendPassword = () => {
    setTitles({
      ...titles,
      title: 'Отправить пароль',
      type: 'password',
      submitButton: 'Отправить',
    });
    setOpen(true);
  };

  const promisePay = (value) => {
    setTitles({
      ...titles,
      title: 'Обещанный платеж',
      type: 'promisePay',
      submitButton: 'Активировать',
      value,
    });
    setOpen(true);
  };

  const reboot = () => {
    dispatch(
      rebootOnt(
        client.service.switchDTO.id,
        client.service.vlan,
        client.service.portId
      )
    );
  };

  return (
    <>
      <AbonentModal
        open={open}
        setOpen={setOpen}
        client={client}
        titles={titles}
      />

      <Card className={classes.card}>
        <Typography>Операции</Typography>
        <CardContent style={{ padding: 10 }}>
          <CustButton
            style={{ marginBottom: 13, height: 35 }}
            size="small"
            fullWidth
            onClick={() => callResult(1)}
          >
            Результат звонка
          </CustButton>
          <CustButton
            style={{ marginBottom: 13, height: 35 }}
            size="small"
            fullWidth
            onClick={() => sendPassword()}
          >
            Отправить пароль
          </CustButton>
          <CustButton
            style={{ height: 35 }}
            size="small"
            fullWidth
            onClick={() => callResult(2)}
          >
            Вторая линия
          </CustButton>
          {clientData?.params !== null &&
            clientData?.params['rts.support.promise.pay.enabled'] ===
              'true' && (
              <CustButton
                style={{ marginTop: 13, height: 35 }}
                size="small"
                fullWidth
                onClick={() =>
                  promisePay(
                    clientData?.params['rts.support.promise.pay.value']
                  )
                }
              >
                Обещанный платеж
              </CustButton>
            )}
          <CustButton
            style={{ height: 35, marginTop: 13 }}
            size="small"
            fullWidth
            onClick={reboot}
          >
            Перезагрузить ONT
          </CustButton>
        </CardContent>
      </Card>
    </>
  );
};

Operations.propTypes = {
  clientData: pt.object,
};

export default Operations;
