export const SIGN_IN = 'SIGN_IN';

// switches
export const GET_SWITCH_LIST = 'GET_SWITCH_LIST';
export const CHANGE_SWITCH_STATUS = 'CHANGE_SWITCH_STATUS';
export const CHANGE_SWITCH_STATUS_IN_PROGRESS =
  'CHANGE_SWITCH_STATUS_IN_PROGRESS';
export const CHANGE_SWITCH_STATUS_IN_ERROR = 'CHANGE_SWITCH_STATUS_IN_ERROR';

// abonents
export const GET_ABONENTS = '/GET_ABONENTS';

export const GET_CLIENTS_REPORT = 'GET_CLIENTS_REPORT';

export const COLLECT_INVOICE = 'COLLECT_INVOICE';
export const MAKE_INVOICE = 'MAKE_INVOICE';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const CLEAR_INVOICE_IN_ERROR = 'CLEAR_INVOICE_IN_ERROR';
export const FORM_INVOICE = 'FORM_INVOICE';
export const CLIENT_LIST = 'CLIENT_LIST';
export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL';
export const REBOOT_ONT = 'REBOOT_ONT';
export const REBOOT_ONT_ERROR = 'REBOOT_ONT_ERROR';

export const CHECK_AUTH = 'CHECK_AUTH';
export const CREATE_ISSUE = 'CREATE_ISSUE';
export const CREATE_ISSUE_ERROR = 'CREATE_ISSUE_ERROR';
export const SEND_PASSWORD = 'SEND_PASSWORD';
export const CREATE_PROMISE_PAY = 'CREATE_PROMISE_PAY';
export const GET_TASK_LIST = 'GET_TASK_LIST';
export const TASK_CHECKING = 'TASK_CHECKING';
export const GET_RESULT = 'GET_RESULT';
export const GET_RESULT_IN_ERROR = 'GET_RESULT_IN_ERROR';

export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';

export const GET_SWITCH_CLIENTS = 'GET_SWITCH_CLIENTS';
export const USER_LIST = 'USER_LIST';

export const LTE_LIST = 'LTE_LIST';
export const LTE_REMOVE = 'LTE_REMOVE';
export const LTE_REMOVE_IN_PROGRESS = 'LTE_REMOVE_IN_PROGRESS';
export const LTE_REMOVE_IN_ERROR = 'LTE_REMOVE_IN_ERROR';
export const LTE_LIST_IN_PROGRESS = 'CHECK_LTE_IN_PROGRESS';
export const LTE_LIST_IN_ERROR = 'CHECK_LTE_IN_ERROR';

export const GET_LTE_BY_ID = 'GET_LTE_BY_ID';
export const SAVE_LTE = 'SAVE_LTE';
export const ADD_LTE = 'ADD_LTE';
export const GET_LTE_BY_ID_LOADING_IN_PROGRESS =
  'GET_LTE_BY_ID_LOADING_IN_PROGRESS';
export const LTE_DIAG = 'LTE_DIAG';
export const LTE_DIAG_IN_PROGRESS = 'LTE_DIAG_IN_PROGRESS';
export const LTE_DIAG_IN_ERROR = 'LTE_DIAG_IN_ERROR';
export const GET_LTE_BY_ID_LOADING_IN_ERROR = 'GET_LTE_BY_ID_LOADING_IN_ERROR';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const ROLE_LIST = 'ROLE_LIST';
export const USER_SAVE = 'USER_SAVE';
export const USER_SAVE_IN_PROGRESS = 'USER_SAVE_IN_PROGRESS';
export const USER_SAVE_IN_ERROR = 'USER_SAVE_IN_ERROR';

export const CHECK_PING = 'CHEK_PING';
export const CHECK_PING_IN_PROGRESS = 'CHECK_PING_IN_PROGRESS';
export const CHECK_PING_IN_ERROR = 'CHECK_PING_IN_ERROR';
export const USER_CHANGE_STATUS = 'USER_CHANGE_STATUS';
export const USER_CHANGE_STATUS_IN_PROGRESS = 'USER_CHANGE_STATUS_IN_PROGRESS';
export const USER_CHANGE_STATUS_IN_ERROR = 'USER_CHANGE_STATUS_IN_ERROR';

export const SET_USER = 'SET_USER';

export const CLIENTS_MOVE = 'CLIENTS_MOVE';
export const CLIENTS_MOVE_IN_PROGRESS = 'CLIENTS_MOVE_IN_PROGRESS';
export const CLIENTS_MOVE_IN_ERROR = 'CLIENTS_MOVE_IN_ERROR';

export const GET_CSV = 'GET_CSV';
export const GET_CSV_ERROR = 'GET_CSV_ERROR';
export const GET_CSV_SUCCESS = 'GET_CSV_SUCCESS';
export const CLEAR_CSV_FILE = 'CLEAR_CSV_FILE';

export const GET_FAILS = 'GET_FAILS';
export const GET_FAILS_IN_PROGRESS = 'GET_FAILS_IN_PROGRESS';
export const GET_FAILS_IN_ERROR = 'GET_FAILS_IN_ERROR';
