import * as types from "../../constants/redux";
import { toast } from "react-toastify";

const initialState = {
  userList: [],
  user: {},
  userRoles: [],
  closeModal: "default",
  onlySwitchLoading: false,
  loading: false,
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case types.USER_LIST:
      return {
        ...state,
        userList: [...payload],
      };
    case types.GET_USER_BY_ID:
      return {
        ...state,
        user: { ...payload },
      };
    case types.USER_SAVE:
      toast.success("Пользователь изменен");
      return {
        ...state,
        loading: false,
        closeModal: "close",
        userList: state.userList.map((client) =>
          client.id === payload.id ? payload : client
        ),
      };
    case types.SET_USER:
      return {
        ...state,
        user: { ...payload },
      };
    case types.USER_SAVE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.USER_SAVE_IN_ERROR:
      toast.error(payload);
      return {
        ...state,
        loading: false,
      };

    case types.ROLE_LIST:
      return {
        ...state,
        userRoles: [...payload],
      };
    case types.USER_CHANGE_STATUS:
      return {
        onlySwitchLoading: false,
        user: { ...payload },
        userList: state.userList.map((client) =>
          client.id === payload.id ? payload : client
        ),
      };
    case types.USER_CHANGE_STATUS_IN_PROGRESS:
      return { ...state, onlySwitchLoading: true };
    case types.USER_CHANGE_STATUS_IN_ERROR:
      toast.error(payload);
      return { ...state, onlySwitchLoading: false };

    case "CHANGE_RESET":
      return { ...state, closeModal: "default" };

    default:
      return state;
  }
}
