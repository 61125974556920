import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import React from "react";

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    font-size: 14px;
    border-radius: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
`;

export default function Toastify() {
  return (
    <StyledContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
